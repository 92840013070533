import styled from "styled-components";
import { NavLink } from "react-router-dom";
import COLORS from "utils/Variables/color";

export const ContentTextPrep = styled.div`
  background: #fff;
  border-radius: 14px;
  height: 100% !important;
`;

export const ContainerAdminTextPrep = styled.div`
  background: #fff;
  border-radius: 14px;
  color: #000;
`;

export const SidebarTextPrep = styled.div`
  background: #fff;
`;

export const ContenuTextPrep = styled.div`
  background: #fff;
  border-radius: 14px;
`;
export const SidebarItems = styled(NavLink)`
  border: 1px solid #aeaeae;
  border-radius: 7px;
  font-family: Roboto !important;
  font-weight: 500;
  font-size: 16px !important;
  color: #000;
  text-decoration: none;
  transition: background-color 0.3s ease;
  &.active {
    background-color: ${COLORS.activeColor};
    color: "#fff";
  }
`;
export const SidebarItemsFiltre = styled(NavLink)`
  border: 1px solid #aeaeae;
  border-radius: 7px;
  font-family: Poppins !important;
  font-weight: 500;
  font-size: 18px !important;
  color: #000;
  text-decoration: none;
  transition: background-color 0.3s ease;
`;
export const AdminTableEmail = styled.table`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: Roboto;
  font-size: 16px;
  margin-top: 12px;
  border: 1px solid #dfe0e1;
  box-shadow: 0px 2px 1px 0px #eaecf0;
`;

export const AdminTableEmailTh = styled.th`
  text-align: center;
  background-color: #f8f9fa !important;
  font-family: Roboto;
  font-weight: 700;
`;
export const Titles = styled.span`
  font-family: Poppins;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
`;
/* ------------------gestion envoi------------------------ */
export const ContGE = styled.div`
  background-color: #fff7e7;
`;
export const TextOvaley = styled.div`
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const Img = styled.img`
  width: 60px;
  /* height: 40px */
`;
export const ImgLangue = styled.img`
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;

export const Langue = styled.div`
  margin: 1.5rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
export const Titre = styled.div`
  width: 35%;
  margin: 1.5rem auto;
  display: flex;
  justify-content: center;
`;
export const Texte = styled.h4`
  text-align: center;
  font-size: 18px;
  font-family: lato;
  font-weight: 300;
  line-height: 50px;
`;
export const Msg = styled.div`
  width: 45%;
`;

export const Bouton = styled.div`
  background: #c83c0c;
  width: 60%;
  height: 5%;
  color: white;
  padding: 8px;
  text-align: center;
  margin: auto;
  font-size: 18px;
`;
export const ColImg = styled.div`
  height: 50%;
`;

export const Date = styled.p`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const Jour = styled.span`
  font-size: 40px;
  text-align: center;
  @media (min-width: 320px) and (max-width: 425px){
  font-size: 25px;
  }
`;
export const MoisAnne = styled.span``;
export const Footer = styled.div`
  background-color: #091b25;
  width: 100%;
  color: #ffffff;
  text-align: center;
`;

export const Card2 = styled.div`
  background-color: #091b25;
  color: #ffffff;
`;
export const DivLogo = styled.div`
  background-color: #12222b;
  width: 150px;
  height: 150px;
  margin: auto;
  text-align: center;
  padding: 2rem 0 2rem 0;
`;
export const DivLogosb = styled(DivLogo)`
  width: 200px;
  height: 180px;
  margin-top: 140px;
  opacity: 0.9;
`;
export const Hotel = styled.h6`
  font-size: 10px;
  margin-top: 0px;
`;
export const Copyright = styled.p`
  font-size: 12px;
`;
/* ---------sidebar--------------- */
export const ContentSidebar = styled.div`
  height: 100vh;
  width: 280px;
  position: fixed;
  top: 0;
  left: 0;
`;
export const Langues = styled.div`
  height: 30px;
  width: auto;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const IMG = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0;
`;
export const Contentpage = styled.div`
  background-color: #fcefee;
  height: max-content;
`;
export const TitreHeader = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  @media (min-width: 320px) and (max-width: 425px){
    font-size: 9px;
  }
`;
export const BarreRight = styled.div`
  margin-top: 0px;
  height: 70px;
  border-left: 0.5px solid #F6EBD6;
`;
export const Footerform = styled.div`
  width: 100%;
  height: auto;
`;
export const TexteAdress = styled.span`
  font-size: 13px;
`;
export const TexteDetail = styled.span`
  font-size: 16px;
  font-weight: 500;
`;
/* --------Sejour------------ */
export const Cadre = styled.div`
  border: 1px solid grey;
  width: auto;
  height: 35px;
  background: #ffff;
  font-family: sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
`;
export const Input = styled.input`
  border: 1px solid #c7451b;
  /* margin-bottom: 5px; */
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: relative;

  /* &:checked::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  } */
`;
export const Label = styled.label`
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 5px;
`;
export const Textarea = styled.textarea`
  width: 100%;
`;

export const TitreQst = styled.label`
  margin-top: 30px;
`;
export const Cercle = styled.input`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid grey;
`;
/* -----------validation----------------- */
export const InputValidation = styled.input`
  margin-top: 10px;
  width: 80%;
  height: 45px;
  border: 1px solid grey;
`;
export const IconCercle = styled.div`
  border: 2px solid #c7451b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin: auto;
`;
export const FormText = styled.div`
  color: #c7451b;
`;
export const ImgCercle = styled.img`
width: 3%;
`