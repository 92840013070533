import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PageNotFound from "pages/NotFound/PageNotFound";
import { privateRoutes } from "./navigation/navigationRoutes/private";
import { publicRoutes } from "./navigation/navigationRoutes/public";
import { NOTFOUND_PATH } from "./navigation/navigationPaths";
import useIsAuthenticate from "hooks/useIsAuthenticatedUser";
import FormPreStay from "pages/Admin/GestionEnvoie/FormPreStay";
import GestionEnvoi from "pages/Admin/GestionEnvoie/GestionEnvoi";

function Roots() {
  const isAuthenticated = useIsAuthenticate();

  const router = createBrowserRouter([
    ...(isAuthenticated ? privateRoutes : publicRoutes),
    {
      path: ":reservationId/hotel/:hotelId/voyage/:voyageurId/formulaire",
      element: <FormPreStay />,
    },
    {
      path: ":reservationId/hotel/:hotelId/voyage/:voyageurId",
      element: <GestionEnvoi />,
    },
    {
      path: NOTFOUND_PATH,
      element: <PageNotFound />,
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}

export default Roots;
