import React, { useState } from "react"
import { ContainerAdminTextPrep, ContentTextPrep, SidebarTextPrep, ContenuTextPrep } from "../../StyledAdmin"
import SidebarTextPrepares from "components/AdminTextPrepares/ATextesPrepares/SidebarTextPrepares";
import { Outlet, useLocation } from "react-router-dom";
// import { Outlet } from "react-router-dom";
import BreadcumpText from "../BreadcumpText";
import { TEXTESPREPARES_NEW, TEXTESPREPARES, TEXTESPREPARES_ALL, TEXTESPREPARES_MESSAGES, UPDATE_EMAIL_PATH } from "routes/navigation/navigationPaths";
import styled from "styled-components";
import SidebarNewTextPrepare from "components/AdminTextPrepares/NouveauTextPrepare/SidebarNewTextPrepare";
// import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
const Heading1 = styled.p`
 color: #000000;
  font-size: 16px; 
  padding-top: 20px;
  font-family: Roboto; 
@media (max-width: 768px) {
  font-size: 24px;
}
`;
function TextesPrepares() {
    const location = useLocation();
    const [categorie, setCategorie] = useState(null);

    const handleFilterChange = (status) => {
        setCategorie(status);
    };
    const handleFilterDelete = () => {
        setCategorie(null);
    };
  return (
    <>
    <BreadcumpText />
    <div className="" style={{ padding: "20px 40px", }} >
        <ContentTextPrep className="container-fluid ">
            <Heading1 className="ms-4 text-secondary">
                {location.pathname === `/${TEXTESPREPARES}`? <>
                <span style={{ color: "#008DF3" }}>Admin </span><span style={{ color: "#000000" }}> / E-mails</span> </> :
                location.pathname === `/${TEXTESPREPARES}/${TEXTESPREPARES_ALL}`?
                <>
                <span style={{ color: "#008DF3" }}>Admin </span><span style={{ color: "#000000" }}> / All</span> </> :
                location.pathname === `/${TEXTESPREPARES}/${TEXTESPREPARES_NEW}`? <>
                <span style={{ color: "#008DF3" }}>Nouveau texte préparé </span><span style={{ color: "#000000" }}> / E-mail</span></> : ""}
            </Heading1>
            <ContainerAdminTextPrep>
                <div className="row">
                    <SidebarTextPrep className="col-md-3 py-1" style={{ borderRadius: "14px" }}>
                    { location.pathname === `/${TEXTESPREPARES}`?
                    <SidebarTextPrepares handleFilterDelete={handleFilterDelete} handleFilterChange={handleFilterChange} /> :
                    location.pathname === `/${TEXTESPREPARES}/${TEXTESPREPARES_NEW}`?
                    <SidebarNewTextPrepare handleFilterDelete={handleFilterDelete} handleFilterChange={handleFilterChange} /> :
                    location.pathname === `/${TEXTESPREPARES}/${TEXTESPREPARES_ALL}` ?
                    <SidebarTextPrepares handleFilterDelete={handleFilterDelete} handleFilterChange={handleFilterChange} /> :
                    location.pathname === `/${TEXTESPREPARES}/${TEXTESPREPARES_MESSAGES}` ?
                    <SidebarTextPrepares handleFilterDelete={handleFilterDelete} handleFilterChange={handleFilterChange} /> :
                    location.pathname === `/${TEXTESPREPARES}/${UPDATE_EMAIL_PATH}` && location.pathname === `/${TEXTESPREPARES}/${DETAIL_TEXTESPREPARES_PATH}` ?
                    <SidebarTextPrepares handleFilterDelete={handleFilterDelete} handleFilterChange={handleFilterChange} /> : ""
                    }
                    </SidebarTextPrep>
                    <ContenuTextPrep className="col-md-9 pb-3">
                        <Outlet context={{ setCategorie, categorie }} />
                    </ContenuTextPrep>
                </div>
            </ContainerAdminTextPrep>
        </ContentTextPrep>
    </div>
    </>
  )
}

export default TextesPrepares