import React, { useState } from "react";
import { Heading5 } from "components/RelationClientDetail/InfoClient/InfoClientCSS";
import { SearchInput, DivDotted, DivProfil } from "components/AdminContain/NouveauVoyageur/NouveauVoyageurCss";
import { IoClose } from "react-icons/io5";
import { useGetVoyageursQuery } from "actions/voyageur/query";

export default function VoyageurSelector() {
    const [valueSearch, setValueSearch] = useState("");
    const [isCompletInfo, setIsCompletInfo] = useState(false);
    const [voyageur, setVoyageur] = useState(null);
    const { data: voyageursData } = useGetVoyageursQuery();
    const voyageurs = voyageursData || [];

    const filteredVoyageurs = voyageurs.filter(
        (v) => v.nom.toLowerCase().includes(valueSearch.toLowerCase()) ||
            v.prenom.toLowerCase().includes(valueSearch.toLowerCase())
    );
    const limitedVoyageurs = filteredVoyageurs.slice(0, 19);
    const completInfo = (selectedVoyageur) => {
        setVoyageur(selectedVoyageur);
        setIsCompletInfo(true);
        // Add logic to update form with selected voyageur info
    };

    const closeFC = () => {
        setIsCompletInfo(false);
        setVoyageur(null);
        // Add logic to reset form
    };

    return (
        <div className="card border-1 border-thin p-5">
            <Heading5 className="text-center">SELECTIONNEZ UN VOYAGEUR</Heading5>
            <SearchInput
                className="mt-4"
                value={valueSearch}
                onChange={(e) => setValueSearch(e.target.value)}
                placeholder="Rechercher par nom"
            />
            {isCompletInfo ? (
                <DivDotted
                    border="none"
                    background="#E0E0E0"
                    radius="5px"
                    className="mt-4 px-2 py-3 d-flex align-items-center justify-content-between border-1 border-thin"
                >
                    <div className="d-flex">
                        <DivProfil className="d-flex align-items-center justify-content-center me-3">
                            {voyageur.prenom[0]}
                        </DivProfil>
                        <span style={{ fontStyle: "italic" }}>
                            {voyageur.prenom} {voyageur.nom}
                        </span>
                    </div>
                    <IoClose
                        style={{ fontSize: "24px", cursor: "pointer" }}
                        onClick={closeFC}
                    />
                </DivDotted>
            ) : (
                <DivDotted
                    border="3px dotted #E0E0E0"
                    className="mt-4 ps-2 pb-3 d-flex flex-column"
                >
                    {limitedVoyageurs.map((v, index) =>
                        v.archived ? null : (
                            <div
                                key={index}
                                style={{ cursor: "pointer" }}
                                className=" d-flex align-items-center pb-3"
                                onClick={() => completInfo(v)}
                            >
                                <DivProfil className="d-flex align-items-center justify-content-center me-3">
                                    {v.prenom[0]}
                                </DivProfil>
                                <span style={{ fontStyle: "italic" }}>
                                    {v.prenom} {v.nom}
                                </span>
                            </div>
                        )
                    )}
                </DivDotted>
            )}
            {/* Add recap section here */}
        </div>
    );
}