import React from "react";
import { NavLink, useParams } from "react-router-dom";
import user from "../../image/usersvg.svg";
import { useNavigate } from "react-router-dom";

const BreadCrumbs = ({ clientName }) => {
  const breadcrumbStyle = {
    fontSize: "20px",
    fontWeight: "400",
    color: "#000000",
    textDecoration: "none",
  };

  const breadCrumbsCardStyle = {
    textAlign: "left",
    fontFamily: "Roboto",
    marginLeft: "20px",
  };

  const breadcrumbNameStyle = {
    fontSize: "20px",
    fontWeight: "400",
    color: "#000000",
    fontFamily: "Roboto",
    cursor: "pointer",
  };

  const separatorStyle = {
    margin: "0 10px",
    color: "#E2E3E4",
  };

  const navigate = useNavigate();
  const { id } = useParams();

  const breadcrumbItems = [
    { label: "Relation client", onClick: () => navigate(-1) },
    { svg: user, href: `/detail-client/${id}` },
    { label: ` > ${clientName || "Chargement..."}` }
  ];

  console.log(clientName, "clientName");

  return (
    <div style={breadCrumbsCardStyle}>
      {breadcrumbItems.map((item, index) => (
        <span key={index}>
          {item.href ? (
            <NavLink to={item.href} style={breadcrumbStyle}>
              {item.svg ? <img src={item.svg} alt="user icon" style={{ width: "24px", marginRight: "8px" }} /> : null}
              <span>{item.label}</span>
            </NavLink>
          ) : (
            <span onClick={item.onClick} style={breadcrumbNameStyle}>{item.label}</span>
          )}
          {index < breadcrumbItems.length - 1 && <span style={separatorStyle}>|</span>}
        </span>
      ))}
    </div>
  );
};

export default BreadCrumbs;
