import React from "react";
import { NavLink } from "react-router-dom";
import user from "image/log.svg";
import { useNavigate } from "react-router-dom";

const BreadCumbReservation = ({ id }) => {
  const breadcrumbStyle = {
    fontSize: "20px",
    fontWeight: "400",
    color: "#000000",
    textDecoration: "none",
  };

  const breadCrumbsCardStyle = {
    textAlign: "left",
    fontFamily: "Roboto",
  };

  const breadcrumbNameStyle = {
    fontSize: "18px",
    fontWeight: "400",
    color: "#000000",
    fontFamily: "Roboto",
    cursor: "pointer",
  };

  const separatorStyle = {
    margin: "0 10px",
    color: "#E2E3E4",
  };

  const navigate = useNavigate();

  const breadcrumbItems = [
    { label: "Admin", onClick: () => navigate(-1) },
    { svg: user, href: "detail-client/:id" },
    { label: "Nouvelle réservation" },
  ];

  return (
    <div style={breadCrumbsCardStyle}>
      {breadcrumbItems.map((item, index) => (
        <span key={index}>
          {item.href ? (
            <NavLink to={item.href} style={breadcrumbStyle}>
              {item.svg ? (
                <img src={item.svg} alt="user icon" style={{ width: "50px" }} />
              ) : null}
              <span>{item.label}</span>
            </NavLink>
          ) : (
            <span onClick={item.onClick} style={breadcrumbNameStyle}>
              {item.label}
            </span>
          )}
          {index < breadcrumbItems.length - 1 && (
            <span style={separatorStyle}></span>
          )}
        </span>
      ))}
    </div>
  );
};

export default BreadCumbReservation;
