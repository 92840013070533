import { BarreRight, Jour, TitreHeader } from "pages/Admin/StyledAdmin";
import React from "react";
import { NavLink } from "react-router-dom";
import "./color.css"

const Header = ({ nombre, titre, navigue, isActive }) => {
    return (
                <NavLink
                    className={`titreLink d-flex align-items-center justify-content-between gap-2 p-1 ${isActive ? "activeTitreLink" : ""}`}
                    to={navigue}
                >
                    <div className="d-flex flex-column align-items-center">
                    <Jour className={`titreLink ${isActive ? "activeTitreLink" : ""}`}>{nombre}</Jour>
                    <TitreHeader className={`titreLink ${isActive ? "activeTitreLink" : ""}`}>{titre}</TitreHeader>
                    </div>
            <BarreRight></BarreRight>
                </NavLink>
    );
};

export default Header;
