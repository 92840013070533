import React from "react";
import { Outlet } from "react-router-dom";
import SpliTemplateScreen from "layouts";
import NavbarLayout from "layouts/navbar/NavbarLayout";
import SidebarComponent from "layouts/sidebar/SidebarComponent";
import { useGetHotel } from "context/AppProvider";

const Loader = () => (
  <div className="loader-container">
  <div className="loader"></div>
</div>
);

const SplitShell = () => {
  const { isLoading } = useGetHotel();

  return isLoading ? (
    <Loader />
  ) : (
    <SpliTemplateScreen>
      <SidebarComponent
        activeLinkBgColor="#FFF"
        activeLinkColor="black"
        linkColor="#FFF"
      />
      <NavbarLayout />
      <Outlet />
    </SpliTemplateScreen>
  );
};

export default SplitShell;
