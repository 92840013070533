import React from "react";
import { useTranslation } from "react-i18next";
import Titre from "components/FormulairePreStay/Titre"
import Bouton from "components/FormulairePreStay/Bouton";
import Text from "components/FormulairePreStay/Text";
import checkCircle from "image/checkCircle.png"
import { ImgCercle } from "../../StyledAdmin";

export default function ValidForm({ handleCancel }) {
  const { t } = useTranslation();
  return (
    <div className="container py-5 text-center" style={{ paddingLeft: "rem" }}>
      <div className="flex justify-content-center">
        <ImgCercle src={checkCircle} alt="Cercle" className="" />
      </div>
      <Titre
        text={t("Formulaire terminé !")}
        style={{
          fontFamily: "Roboto",
          fontSize: "28px",
          fontWeight: 600,
        }}
      />
      <Text content={t("Merci pour votre temps.")} color={"#555555"} />
      <div style={{ textAlign: "center" }}>
        <Text content={t("En cas de demande supplémentaire, n'hésitez pas à nous écrire à l'adresse suivante")}
          color={"#555555"} />
        <p><Text content={"contact@azalai.com"} color={"#555555"} /></p>
      </div>
      <div className="flex justify-content-center"><Bouton name={t("Précédent")} type="button" onClick={handleCancel} /></div>
    </div>
  );
}
