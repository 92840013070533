import { ApiMananger } from "actions/rtkquery";
import {
  voyageurEndpoint,
  voyageurReservationEndpoint,
} from "routes/api/endpoints";

export const voyageurQueries = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    getVoyageurs: builder.query({
      query: () => ({
        url: voyageurEndpoint.addNewVoyageur(),
        method: "GET",
        // params: { user: userId },
      }),
      providesTags: ["voyageurReservation"],
    }),
    getVoyageurById: builder.query({
      query: (id) => ({
        url: voyageurEndpoint.getVoyageurId.replace(":id", id),
        method: "GET",
      }),
      providesTags: ["voyageurReservation"],
    }),
    getVoyageurReservations: builder.query({
      query: (id) => ({
        url: voyageurReservationEndpoint.getVoyageurReservations.replace(
          ":id",
          id
        ),
        method: "GET",
      }),
      providesTags: ["voyageurReservation"],
    }),
  }),
});

export const {
  useGetVoyageurByIdQuery,
  useGetVoyageurReservationsQuery,
  useGetVoyageursQuery,
} = voyageurQueries;
