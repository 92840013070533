import { ApiMananger } from "actions/rtkquery";

export const hotelApi = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    getHotel: builder.query({
      query: (id) => ({
        url: "hotelier/user/",
        method: "GET",
      }),
    }),
    getAllHotel: builder.query({
      query: () => ({
        url: "",
        method: "GET",
      }),
    }),
    getHotelierId: builder.query({
      query: (id) => ({
        url: `hoteliers/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetHotelQuery, useGetHotelierIdQuery } = hotelApi;
