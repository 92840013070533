import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  display: flex;
  border: none;
  justify-content: space-between;
  align-items: center;
  margin-top: -20px;
  /* margin-left: -10px; */
`;
const CardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
  margin-top: 5px
`;

const Image = styled.img`
  width: "25px";
  margin-top: 3px;
`;

const Text = styled.p`
text-align: left;
  font-size: 26px;
  margin-top: 17px;
  margin-left: 10px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 20px;
  color:#000000;
`;
const CompleteElement = styled.p`
letter-spacing: 0.523886501789093px;
font-family: Roboto;
 font-weight: 400;
 font-size: 18px;
 color: #000000;
 margin-top: 21px;
`;

const CardHeader = ({ image, text, Complete }) => {
  return (
    <CardContainer >
      <CardHeaderContainer>
        <Image src={image} />
        <Text>{text}</Text>
      </CardHeaderContainer>
      <CompleteElement>{Complete}</CompleteElement>
    </CardContainer>
  );
};

export default CardHeader;
