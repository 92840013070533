import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import CardInfoClient from "./CardInfoClient";
import PhotoProfile from "./PhotoProfile";
import InfoPersonnel from "./InfoPersonnel";
import ListeTaches from "./ListeTaches";
import Checkbox from "./Checkbox";
import { Boutton, Heading2, Heading4, Heading5 } from "./InfoClientCSS";
import { MdMailOutline } from "react-icons/md";
import DrapeauSenegal from "../../../image/DrapeauSenegal.jpg";
import { BiEditAlt, BiLogoFacebookCircle } from "react-icons/bi";
import insta from "image/instagram.png";
import micro from "image/megaphone.png";
import checky from "../../../image/checky.png";
import COLORS from "utils/Variables/color";
import FONTS from "utils/Variables/police";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import "../CalendrierduClient/calendrier.css";

const ContIcone = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 114.23px;
  background-color: ${COLORS.background};
  text-align: center;
  /* padding: 0px 1.71px 1.5px 0px; */
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ColorIcone = {
  color: COLORS.blueColor,
  fontSize: "19px",
};
const boutonStyle = {
  width: "80px",
  height: "38px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "47px",
  fontFamily: FONTS.Roboto,
  lineHeight: "18px",
};

const calculerAge = (dateNaissance) => {
  const today = new Date();
  const birthDate = new Date(dateNaissance);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Ajuster l'âge si l'anniversaire n'est pas encore passé cette année
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};
export default function CardLeft({ client }) {
  const taches = [
    { date: "14 Mars 2024", title: "Ne prend pas de sucre dans son café" },
    { date: "13 Mars 2024", title: "Il est dans la chambre 245" },
  ];
  const infoperso = [
    {
      label: "Age :",
      valeur: `${calculerAge(client.date_naissance)} ans`,
      classCont: "d-flex gap-3 text-align-center",
    },
    {
      label: "Langue :",
      valeur: client.langue,
      ms: "margin-left-negative",
      classCont: "d-flex gap-3 text-align-center",
    },
    {
      label: "Pays :",
      img: DrapeauSenegal,
      classimg: "me-2 clasimgt",
      valeur: "Sénégal",
      classCont: "d-flex gap-3 text-align-center",
    },
  ];
  const infoSocial = [
    {
      label: "Genre :",
      valeur: client.genre,
      classCont: "d-flex gap-1 text-align-center",
    },
    {
      label: "Société :",
      valeur: "Non disponible",
      classCont: "d-flex gap-1 text-align-center",
    },
    {
      label: "Poste :",
      valeur: "Non disponible",
      classCont: "d-flex gap-1 text-align-center",
    },
    {
      label: "Résidence :",
      valeur: client.adresse,
      classCont: "d-flex gap-1 text-align-center",
    },
  ];
  const coordonnees = [
    {
      label: "WhatsApp :",
      valeur: client.telephone,
      classCont: "d-flex gap-1 text-align-center",
    },
    {
      label: "E-mail :",
      valeur: client.email,
      classCont: "d-flex gap-1 text-align-center",
    },
  ];
  const [selectedOptions, setSelectedOptions] = useState(null);
  const handleCheckboxChange = (event) => {
    setSelectedOptions(event.target.value);
  };
  const options = [
    { value: "allowed", label: "Autorisé" },
    { value: "notAllowed", label: "Non autorisé" },
  ];
  return (
    <CardInfoClient>
      <div className="card-body px-3 d-flex flex-column justify-content-center border: none ">
        <div className="d-flex flex-column align-items-center">
          <PhotoProfile
            width="80px"
            height="80px"
            fontSize="44px"
            marginTop="30px"
          />
          <div className="d-flex align-items-center justify-content-center mt-3">
            <div className="text-center mt-1">
              <Heading2>
                {client.prenom} {client.nom}
              </Heading2>
            </div>
            <div style={{ marginBottom: "3px" }}>
              <img src={checky} className="ml-2" />
            </div>
          </div>
          <div className="d-flex gap-2">
            <ContIcone>
              {" "}
              <BiLogoFacebookCircle style={ColorIcone} />
            </ContIcone>
            <ContIcone>
              {" "}
              <img src={insta} style={{ width: "20px" }} />
            </ContIcone>
            <ContIcone>
              {" "}
              <FontAwesomeIcon icon={faLinkedin} style={ColorIcone} />
            </ContIcone>
          </div>
          <div>
            <Boutton className="pt-2 mt-2" style={boutonStyle}>
              <Heading5>
                <MdMailOutline style={{ fontSize: "14px", color: "#000000" }} />
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "5px",
                    color: "#000000",
                  }}
                >
                  E-mail
                </span>
              </Heading5>
            </Boutton>
          </div>

          <div className="nom mt-4" style={{}}>
            {infoperso.map((info) => (
              <InfoPersonnel
                key={info}
                label={info.label}
                valeur={info.valeur}
                img={info.img}
                ms={info.ms}
                classCont={info.classCont}
                classimg={info.classimg}
              />
            ))}
          </div>
        </div>

        <hr className="margin-negative" />

        <div className="d-flex justify-content-between align-items-center mb-2">
          <Heading4 style={{ marginLeft: "3px" }}>
            Informations sociales
          </Heading4>
          <img
            src={micro}
            alt=""
            style={{ width: "18px", marginTop: "-10px" }}
          />
        </div>
        {infoSocial.map((info) => (
          <InfoPersonnel
            key={info}
            label={info.label}
            valeur={info.valeur}
            classCont={info.classCont}
          />
        ))}
        <hr className="margin-negative" />
        <div className="d-flex justify-content-between align-items-center mb-2">
          <Heading4 className="mb-2" style={{ marginLeft: "2px" }}>
            Coordonnées
          </Heading4>
          <BiEditAlt
            style={{ color: "blue", marginTop: "-10px", fontSize: "18px" }}
          />
        </div>
        {coordonnees.map((info) => (
          <InfoPersonnel
            key={info}
            label={info.label}
            valeur={info.valeur}
            classCont={info.classCont}
          />
        ))}
        <hr className="margin-negative" />

        <ListeTaches taches={taches} />

        <hr className="margin-negative" />

        <Heading4>Envoie d'e-mail</Heading4>
        <Checkbox
          value={selectedOptions}
          onChange={handleCheckboxChange}
          flexDirection="row"
          justifycontent="justify-content-between"
          options={options}
          name="autorisé"
        />
        <hr className="margin-negative" />
        <Boutton
          className="p-2 d-flex justify-content-center align-items-center text-center rounded-5 m-2"
          data-bs-toggle="modal"
          data-bs-target="#modalInfo"
        >
          <div style={{ fontSize: "14px", fontWeight: "500" }}>
            Provenance des informations
          </div>
        </Boutton>
      </div>
    </CardInfoClient>
  );
}
