import React from "react";

const CustomButton = ({
  className,
  backgroundColor,
  color,
  border,
  padding,
  margin,
  fontSize,
  fontWeight,
  width,
  height,
  borderRadius,
  children,
  onClick,
  display,
  justifyContent,
  alignItems,
  type,
}) => {
  return (
    <button
      className={className}
      onClick={onClick}
      type={type}
      style={{
        backgroundColor: backgroundColor,
        fontWeight: fontWeight,
        color: color,
        border: border,
        padding: padding,
        margin: margin,
        fontSize: fontSize,
        width: width,
        height: height,
        borderRadius: borderRadius,
        display: display,
        justifyContent: justifyContent,
        alignItems: alignItems,
      }}>
      {children}
    </button>
  );
};

export default CustomButton;
