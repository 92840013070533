import styled from "styled-components";
import BgImage from "image/image/bgForm.png";

export const Container = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: start;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
export const IconContainer = styled.div`
  border-radius: 10px;
  border: 2px solid #00000020;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Title = styled.h1`
    font-family: Roboto;
    font-size: 32.28px;
    font-weight: 600;
    line-height: 38.74px;
    letter-spacing: -0.9684px;

    @media (max-width: 768px) {
        font-size: 24px;
    }
    }
`;
export const Select = styled.select`
  border: none;
  width: 100%;
  outline: none;
  box-shadow: none;
  color: #212529;
`;
export const CustomInput = styled.input`
  border: none;
  outline: none;
  width: 155%;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0px;
  @media(min-width: 320px) and (max-width: 767px){
  font-size: 11px;
  }
`;
export const CustomTextArea = styled.textarea`
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0px 0px 0px 0px;
  height: 100px;
  resize: none;
`;
export const NeumorphicButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 5px;
  gap: 10px;
  margin-right: 10%;
`;
export const ResponseText = styled.span`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
`;
export const Img = styled.img`
  width: 4%;
`;
export const NeumorphicButton = styled.button`
  background: ${(props) =>
    props.active ? "#4CAF50" : "#e0e0e0"}; /* Vert si actif, gris sinon */
  border: none;
  border-radius: 20px;
  width: 40px;
  height: 25px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.active ? "flex-end" : "flex-start")};
  padding: 0 4px;
`;
export const SmallButton = styled.div`
  width: 18px;
  height: 18px;
  border: none;
  border-radius: 50%;
  background: white;
  transition: all 0.3s ease;
`;
export const Button = styled.button`
  padding: 10px 36px;
  font-size: 15px;
  font-weight: 500;
  background-color: #d26129;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-family: "Inter", sans-serif;

  @media (max-width: 768px) {
    padding: 10px 16px;
  }
`;
export const Icone = styled.span`
  font-size: 25px;
`;
export const Contain = styled.div`
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-position: center;
`;
export const ContainerAccueil = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: auto auto auto;
  align-items: center;
  gap: 1rem;
  padding: 4% 9%;
  @media (max-width: 661px) {
    display: block;
  }
`;
export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 1.5rem;
  color: #d26129;
  font-family: "Roboto", sans-serif;
  grid-column: 1 / 2;
`;
export const Transform = styled.p`
  font-weight: 600;
  font-size: 38px;
  @media (min-width: 320px) and (max-width: 1024px) {
    font-size: 20px;
  }
`;
export const Message = styled.p`
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 0;
  @media (min-width: 320px) and (max-width: 1024px) {
    font-size: 12px;
  }
`;
export const ContainImage = styled.div`
  grid-column: 2 / 2;
  margin-left: 27px;
  @media (max-width: 661px) {
    display: none;
    margin-left: 0;
  }
`;
export const Image = styled.img`
  width: 100%;
`;
export const ContainTextImage = styled.div`
  display: flex;
  gap: 1rem;
`;
export const Image2 = styled.img`
  width: 1%;
  height: 200px;
  @media (min-width: 320px) and (max-width: 1024px) {
    height: 100px;
  }
`;
export const TextUtils = styled.p`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
`;
