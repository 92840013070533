import { Copyright, DivLogo, Hotel } from "pages/Admin/StyledAdmin";
import React from "react";
import { Link } from "react-router-dom";
import azalai from "image/azalai.png"
import { useTranslation } from "react-i18next";


function FooterAccueil() {
    const { t } = useTranslation();
    return (
        <>
            <DivLogo>
                <img src={azalai} alt="" />
                <h5 className="mt-1"> Azalaï </h5>
                <Hotel>Hôtel Dakar</Hotel>
            </DivLogo>
                <p className="mt-3">7 rue Mayran 75009 Paris</p>
                <p className="mt-1">+33 (0)1 42 80 00 00</p>
                <Link className="lien mt-1">www.maisonmere.co</Link>
                <div className="mt-4">
                    <Copyright>{t("© 2024 Maison Mère - Tous droits réservés")}</Copyright>
                    <Link className="lien1 my-0">{t("Ne plus recevoir nos e-mails")}</Link>
                </div>
        </>
    )
}

export default FooterAccueil;