import React from "react";
import { RxPerson } from "react-icons/rx";
import { FaArchive } from "react-icons/fa";
// import { FaPlus } from "react-icons/fa6";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import styled from "styled-components";

const DateTexte = styled.p`
color: #252C32;
font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height:28px;
letter-spacing: -0.006em;
text-align: right;

`
function ConversationAtraiterCard({ text, date }) {
  return (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="col-md-1 py-2">
        <div className="text-white rounded-circle text-center"
         style={{ height: "25.46px", width: "25.35px", background: "#E76F51" }}>
          <RxPerson style={{ fontSize: "13px" }} />
         </div>
      </div>
      <div className="col-md-11 card p-0"
        style={{
          boxShadow: "0px 0.88px 10.78px 0px #00000026",
          backgroundColor: "#FFFFFF",
          padding: "20px",
          borderRadius: "14.26px",
        }}
      >
        <div style={styledRelationClientDetail.arrowLeft}></div>
        <div className="card-body p-2">
          <div className="d-flex justify-content-between gap-1">
            <p style={{ fontFamily: "inter", fontWeight: 400, color: "#000000", fontSize: "20px" }} className="m-3">{text}</p>
            <FaArchive className="" style={{ color: "#DEDEDE", fontSize: "25px", background: "#FFFFFF" }} />
          </div>
          <DateTexte className="text-end p-0 m-0">{date}</DateTexte>
        </div>
      </div>
    </div>
  )
}

export default ConversationAtraiterCard
