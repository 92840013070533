import React from "react";
import { RxPerson } from "react-icons/rx";
import styled from "styled-components";

const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isClient ? "flex-start" : "flex-end")};
  margin-bottom: 12px;
  padding-left: 15px;
`;

const MessageCard = styled.div`
  background-color: ${(props) => (props.isClient ? "#FEF2CB" : "#F0F0F0")};
  margin-top: 35px;
  box-shadow: 1px 1px 3px gray;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  width: 600px;
  height: auto;
  align-items: center;
  color:#000000;
`;

const Avatar = styled.div`
  background-color: #343a40;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 1.5rem;
  margin-right: 20px;
`;

// const ConText = styled.p`
// font-family: Inter;
// font-size: 18px;
// font-weight: 400;
// line-height: 28px;
// `
// const NameHotel = styled.span`
// border: 1.02px solid #CBCBCB;
// width: 60px;
// border-radius: 5.11px;
// padding: 3px
// `
// const TextBas = styled.p`
// margin-top: 30px;
// `

// const DateText = styled.p`
// color: #252C32;
// font-family: Roboto;
// font-size: 16px;
// font-weight: 400;
// line-height: 18px;
// letter-spacing: -0.006em;
// `

function ConversationCard({ text, date, id, nameHotel, client, textBas }) {
  return (
    <MessageWrapper isClient={client}>
      <MessageCard isClient={client}>
        {client && (
          <Avatar>
            <RxPerson />
          </Avatar>
        )}
        <div>
          <div dangerouslySetInnerHTML={{ __html: text }} style={{ marginTop: "20px" }}/>
          <p className="text-end " style={{ color: "#566577" }}>{date}</p>
        </div>
      </MessageCard>
    </MessageWrapper>
  );
}

export default ConversationCard;
