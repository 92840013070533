import React, { useState } from "react";
import styled from "styled-components";
import Titre from "components/FormulairePreStay/Titre";
import Text from "components/FormulairePreStay/Text";
import { useSelector } from "react-redux";
import { useGetServicesForFormularQuery } from "actions/service/query";
import { CheckboxContainer } from "pages/Admin/GestionEnvoie/TabsFormPreStay/StyledTabsForm";
import { useFormContext } from "react-hook-form";

// Styled Components
const Container = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  padding-bottom: 25px;
  @media (max-width: 767px) {
    display: block;
  }
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const LargeIcon = styled.img`
  width: 15%;
  border-radius: 50%;
`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: end;
`;
const PriceText = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
`;

const QuantityButton = styled.button`
  background-color: #d26129; /* Change color as needed */
  color: white;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #b14e1f; /* Slightly darker shade for hover effect */
  }

  &:active {
    background-color: #9f3d10; /* Even darker shade when active */
  }

  &:disabled {
    background-color: #ccc; /* Gray color for disabled state */
    cursor: not-allowed;
  }
`;

const QuantityDisplay = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin: 0 10px;
`;

// ServicePayant Component
const ServicePayant = () => {
  const userId = useSelector((state) => state?.persist?.auth?.user_id);
  const { data: services } = useGetServicesForFormularQuery(userId);
  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceQuantities, setServiceQuantities] = useState({}); // Object to hold quantity for each service

  const { register, setValue } = useFormContext();

  const handleSelect = (serviceId) => {
    const updatedSelection = selectedServices.includes(serviceId)
      ? selectedServices.filter((id) => id !== serviceId) // Deselect if already selected
      : [...selectedServices, serviceId]; // Select new service

    setSelectedServices(updatedSelection);
    setValue("servicesPayants", updatedSelection); // Update the form field with selected service IDs
  };

  const handleQuantityChange = (serviceId, increment) => {
    setServiceQuantities((prevQuantities) => {
      const newQuantity = (prevQuantities[serviceId] || 0) + increment;
      const updatedQuantities = {
        ...prevQuantities,
        [serviceId]: newQuantity < 0 ? 0 : newQuantity, // Prevent negative quantities
      };

      setValue("serviceQuantities", updatedQuantities); // Store quantities in form
      return updatedQuantities;
    });
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <Container className="col-lg-8 col-sm-12 col-md-12 col-xl-6">
        <Titre
          text="Services Payants"
          style={{
            color: "#D26129",
            fontFamily: "Roboto",
            fontSize: "25.28px",
            fontWeight: "600",
          }}
        />
        {services?.data?.map((service) => (
          <Content key={service?.id}>
            <Item>
              <LargeIcon src={service?.image_url} alt="Service" />
              <label htmlFor={`checkbox-${service?.id}`}>
                <Description>
                  <Text
                    content={
                      <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                        {service.title}
                      </span>
                    }
                  />
                  <Text
                    content={
                      <span
                        style={{ fontSize: "14px" }}
                        dangerouslySetInnerHTML={{ __html: service.description }}
                      />
                    }
                  />
                </Description>
              </label>
            </Item>
            <div>
            <PriceContainer>
              <PriceText>
                <label
                  htmlFor={`checkbox-${service?.id}`}
                  className="d-flex flex-column"
                >
                  <Text
                    content={
                      <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                        {service?.prix_unitaire}
                        <span style={{ fontWeight: "normal", color: "gray" }}>
                          {" "}
                          FCFA{" "}
                        </span>
                      </span>
                    }
                  />
                  <Text content={<span style={{ fontSize: "15px" }}>par personne</span>} />
                </label>
              </PriceText>
              <CheckboxContainer>
                <div className="round">
                  <input
                    {...register("servicesPayants")}
                    type="checkbox"
                    id={`checkbox-${service?.id}`}
                    checked={selectedServices.includes(service.id)}
                    onChange={() => handleSelect(service.id)}
                  />
                  <label htmlFor={`checkbox-${service?.id}`}></label>
                </div>
              </CheckboxContainer>
            </PriceContainer>
            {selectedServices.includes(service?.id) && (
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <QuantityButton
                  type="button"
                  onClick={() => handleQuantityChange(service?.id, -1)}
                  disabled={serviceQuantities[service?.id] === 0}
                >
                  -
                </QuantityButton>
                <QuantityDisplay>{serviceQuantities[service?.id] || 0}</QuantityDisplay>
                <QuantityButton
                  type="button"
                  onClick={() => handleQuantityChange(service?.id, 1)}
                >
                  +
                </QuantityButton>
              </div>
            )}
            </div>
          </Content>
        ))}
      </Container>
    </div>
  );
};

export default ServicePayant;
