import React from "react";
import styled from "styled-components";
import { FaInstagram, FaTwitter, FaLinkedinIn, FaFacebookSquare } from "react-icons/fa";

const ContentIcon = styled.div`
  display: flex;
  gap: 30px;
  justify-content: start; /* Centrer les images */
`;

const Link = styled.a`
  color: grey;
  font-size: 30px;
`;

export default function Socio() {
  return (
    <div>
      <ContentIcon>
      <Link href="#" target="_blank"><FaInstagram/></Link>
      <Link href="#" target="_blank"><FaTwitter/></Link>
      <Link href="#" target="_blank"><FaLinkedinIn/></Link>
      <Link href="#" target="_blank"><FaFacebookSquare/></Link>
      </ContentIcon>
    </div>
  );
}
