/*
|--------------------------------------------------------------------------
! All Your Application Navigations Paths Will Be Here
|--------------------------------------------------------------------------
*/

/**
 * @readonly
 * ! PUBLIC ROUTES
 */
export const PUBLIC_PATH = "/";
export const LOGIN_PATH = "/";
export const REGISTER_PATH = "signup";
export const FORGETPASSWORD = "/auth/forgetPassword";
export const RESETPASSWORD = "/auth/resetPassword";
export const RESETMPD = "/auth/resetMpd";

/**
 * @readonly
 * ! PROTECTED ROUTES
 */
export const ACCEUIL_PATH = "acceuil";
export const PRODUCTS_PATH = "products";
export const RELATION_CLIENT_PATH = "acceuil";
export const MARKETING_PATH = "marketing-dashboard";
export const ADMIN_PATH = "admin-dashboard";
export const DASHBOARD_ADMIN_PATH = "dashboard-admin";
export const TEXTESPREPARES = "textes-prepares";
export const DASHBOARD_RELATION_PATH = "acceuil";
export const ELEMENTS_TRAITES_PATH = "dashboard-du-client/elements-traites";
export const DETAIL_CLIENT_PATH = "detail-client";
export const GESTION_PATH = "gestionaire-du-client";
export const STATISTIQUE = "statistique";
export const PLUS = "plus";
export const DETAIL_CLIENT = "details-client"
export const TEXTESPREPARES_MESSAGES = "messages"
export const TEXTESPREPARES_MAIL = "email"
export const TEXTESPREPARES_ALL = "all"
export const TEXTESPREPARES_FORMULAIRE = "formulaire"
export const TEXTESPREPARES_NEW = "new-create"
export const ELEMENT_TRAITE = "elements-traites"
export const DEMANDE_CLIENT_PATH = "demande-client";
export const GESTION_VOYAGEUR_PATH = "gestion-voyageur";
export const NOUVEAU_VOYAGEUR_PATH = "gestion-voyageur/nouvau-voyageur";
export const GESTION_RESERVATION_PATH = "gestion-reservation";
export const DETAIL_RESERVATION_PATH = "gestion-reservation/detail-reservation";
export const NOUVELLE_RESERVATION_PATH = "gestion-reservation/nouvelle-reservation";
export const UPDATE_RESERVATION_PATH = "gestion-reservation/update-reservation";
export const HOTEL_RESERVATION_PATH = "Azalai-Hotel";
export const GESTION_ENVOIS_PATH = "gestion-envoies";
export const FORMULAIRE_PRE_STAY_PATH = "Azalai-Hotel";
export const A_PROPOS_DE_NOUS = "apropos-nous";
export const LES_SERVICES = "services";
export const VOTRE_SEJOUR_PATH = "votre-sejour";
export const VALIDATION_PATH = "validation";
export const UPDATE_VOYAGEUR_PATH = "gestion-voyageur/update-voyageur";
export const UPDATE_EMAIL_PATH = "update-email";
export const TEXTESPREPARES_SEJOUR_PATH = "sejour";
export const TEXTESPREPARES_BEFORE_PATH = "before-sejour";
export const TEXTESPREPARES_AFTER_PATH = "after-sejour";
export const DETAIL_TEXTESPREPARES_PATH = "detail-texte-prep";
/**
 * @readonly
 * ! NOT FOUND ROUTE
 */
export const NOTFOUND_PATH = "*";
