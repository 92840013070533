import { ApiMananger } from "actions/rtkquery";
import {
  reservationEndpoint,
  deleteReservationEndpoint,
  voyageurReservationEndpoint,
} from "routes/api/endpoints";

export const reservationMutations = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    addNewReservation: builder.mutation({
      query: (reservationData) => ({
        url: reservationEndpoint.addNewReservation(),
        method: "POST",
        body: reservationData,
      }),
      invalidatesTags: ["voyageurReservation"],
    }),
    updateReservation: builder.mutation({
      query: ({ id, reservationData }) => ({
        url: reservationEndpoint.updateReservation.replace(":id", id),
        method: "PUT",
        body: reservationData,
      }),
      invalidatesTags: ["voyageurReservation"],
    }),
    deleteReservation: builder.mutation({
      query: (id) => ({
        url: deleteReservationEndpoint.suppReservation.replace(":id", id),
        method: "DELETE",
      }),
      invalidatesTags: ["voyageurReservation"],
    }),
    updateVoyageurReservations: builder.mutation({
      query: ({ id, voyageurReservationData }) => ({
        url: voyageurReservationEndpoint.updateVoyageurReservations.replace(
          ":id",
          id
        ),
        method: "PUT",
        body: voyageurReservationData,
      }),
      invalidatesTags: ["voyageurReservation"],
    }),
  }),
});

export const {
  useAddNewReservationMutation,
  useUpdateVoyageurReservationsMutation,
  useUpdateReservationMutation,
  useDeleteReservationMutation,
} = reservationMutations;
