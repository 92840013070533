import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ flexDirection, options, my, me, justifyContent, value, onChange, name }) => {
  const flexDirectionClass = flexDirection === "row" ? "flex-row gap-2" : "flex-column";

  return (
    <div className={`d-flex ${justifyContent} ${flexDirectionClass}`}>
      {options.map((option) => (
        <label className={me} key={option.value}>
          <input
            className={`me-2 ${my}`}
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={onChange}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

Checkbox.propTypes = {
  flexDirection: PropTypes.oneOf(["row", "column"]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  my: PropTypes.string,
  me: PropTypes.string,
  justifyContent: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

Checkbox.defaultProps = {
  flexDirection: "column",
  my: "",
  me: "",
  justifyContent: ""
};

export default Checkbox;
