import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Style pour le conteneur des détails
const DetailsContainer = styled.div`
  font-size: ${(props) => props.size || "14px"};
  color: ${(props) => props.color || "black"};
`;

// Style pour le titre
const Title = styled.h2`
  font-size: ${(props) => props.size || "20px"};
  color: ${(props) => props.calor || "white"};
  padding-bottom: 12px;
`;

// Liste stylisée
const List = styled.ul`
  padding-left: 0px;
  list-style-type: none;
  font-size: ${(props) => props.size || "14px"};
  color: ${(props) => props.color || ""};
`;

const ListItem = styled.li`
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

const Icon = styled.span`
  margin-right: 10px;
  font-size: 18px;
  color: ${(props) => props.color || "inherit"};
  flex-shrink: 0;
   @media (max-width: 768px) {
   margin-right: 0px;
  }
`;

// Style pour les liens
const Link = styled.a`
  display: block;
  color: ${(props) => props.color || "inherit"};
  text-decoration: underline;
  font-size: 18px;
  padding-bottom: 16px;
  word-wrap: break-word;
  word-break: break-word;
  &:hover {
    text-decoration: underline;
  }
    @media (max-width: 767px){
    font-size: 14px;
    }
`;

function Details({ size, color, title, text, icons, urls, iconColors, children }) {
  const items = text ? text.split(" / ") : [];

  return (
    <DetailsContainer size={size} color={color}>
      <Title size={size} color={color}>{title}</Title>
      {items.length > 0 ? (
        <List>
          {items.map((item, index) => (
            <ListItem key={index}>
              {icons && icons[index] && (
                <Icon color={iconColors ? iconColors[index] : color}>
                  <FontAwesomeIcon icon={icons[index]} />
                </Icon>
              )}
              <Link href={urls && urls[index] ? urls[index] : "#"} color={color}>
                {item}
              </Link>
            </ListItem>
          ))}
        </List>
      ) : (
        children
      )}
    </DetailsContainer>
  );
}

export default Details;
