import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Editor } from "primereact/editor";
import { DivSignePlus } from "components/RelationClientDetail/InfoClient/InfoClientCSS";
import { Button, Input, Label } from "./NouvelleEmailCss";
import Checkbox from "components/RelationClientDetail/InfoClient/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import { TEXTESPREPARES } from "routes/navigation/navigationPaths";
import EmailProvider from "./NouvelleEmailProvider";
// import { useAddTextePrepareMutation, useGetTextesPrepareQuery, useUpdateTextePrepareMutation } from "actions/apiSliceRTK";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAddTextePrepareMutation, useUpdateTextePrepareMutation } from "actions/textPrepare/mutation";
import { useGetTextesPrepareQuery } from "actions/textPrepare/query";
import DrapeauAngleterre from "image/DrapeauAngleterre.jpg";
import DrapeauFrance from "image/DrapeauFrance.png";
import "./CountryDropdown.css"

const NouvelleEmailForm = () => {
  const { id } = useParams();
  const isUpdate = !!id;
  const idAsInt = parseInt(id, 10);
  const navigate = useNavigate();
  const { register, handleSubmit, control, formState: { errors }, watch, reset, setValue } = useFormContext();
  const formData = watch();
  const statutWatch = watch("statut");
  console.log({ statutWatch });

  const switchEmail = () => {
    reset({
        nom: "",
        sujet: "",
        mode: "",
        description: "", });
    navigate(`/${TEXTESPREPARES}`);
  };

    // Id hotelier connecté
    const userId = useSelector((state) => state?.persist?.auth.user_id);
  const [addNewReservationMutation] = useAddTextePrepareMutation();
  const { data: emailsData, refetch } = useGetTextesPrepareQuery( userId )
  const emails = emailsData?.data || [];
  // const emailEdit = emails.find((email) => idAsInt === email.id);
  const [emailEdit, setEmailEdit] = useState(null);
  const [editorKey, setEditorKey] = useState(0);
  const [updateTextPrepareMutation] = useUpdateTextePrepareMutation();
  const onSubmit = async () => {
    try {
      if (isUpdate && emailEdit) {
            const emailDataEdit = {
                user: userId,
                nom_email: formData.nom,
                sujet_email: formData.sujet,
                contenu: formData.description,
                statut: formData.statut
            };
                const data = await updateTextPrepareMutation({ id: emailEdit.id, textePrepareData: emailDataEdit });
                toast.success("Email modifié avec succès");
                console.log("Email modifié avec succès!", data);
                refetch();
      } else {
        const emailData = {
            user: userId,
            nom_email: formData.nom,
            sujet_email: formData.sujet,
            contenu: formData.description,
            statut: formData.statut
        };
            const data = await addNewReservationMutation(emailData);
            refetch();
            console.log("Email envoyé avec succès!", data);
            toast.success("Email envoyé avec succès");
      }
      reset({
          nom: "",
          sujet: "",
          statut: "",
          description: "", });
    } catch (error) {
        console.error("Erreur l'envoie de l'email' :", error);
        toast.error("Erreur l'envoie de l'email'");
    }
  };

  const renderHeader = () => {
    return (
      <div className="ql-toolbar ql-snow d-flex ">
        <span className="ql-formats">
          <button className="ql-bold" aria-label="Bold"></button>
          <button className="ql-italic" aria-label="Italic"></button>
          <button className="ql-underline" aria-label="Underline"></button>
          <select className="ql-color" aria-label="Text Color">
            <option value="red"></option>
            <option value="green"></option>
            <option value="blue"></option>
            <option style={{ border: "1px solid #ccc" }} value="white"></option>
            <option value="orange"></option>
            <option value="purple"></option>
            <option value="black"></option>
          </select>
          <select className="ql-background" aria-label="Background Color">
            <option value="red"></option>
            <option value="green"></option>
            <option value="blue"></option>
            <option value="orange"></option>
            <option style={{ border: "1px solid #ccc" }} value="white"></option>
            <option value="purple"></option>
            <option value="black"></option>
          </select>
        </span>
        <span className="ql-formats">
          <button className="ql-align" value="" aria-label="Align Left"></button>
          <button className="ql-align" value="center" aria-label="Align Center"></button>
          <button className="ql-list" value="ordered" aria-label="Ordered List"></button>
          <button className="ql-list" value="bullet" aria-label="Unordered List"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-link" aria-label="Insert Link"></button>
          <button className="ql-image" aria-label="Insert Image"></button>
        </span>
        <div className="d-flex align-items-center">
          <DivSignePlus className="mt-1 me-2">+</DivSignePlus>
          <Label style={{ color: "#FFC857" }}>Ajouter une balise</Label>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  // const optionsMode = [
  //   { value: "allowed", label: "Utiliser les shortcodes de l'hôtel" },
  //   { value: "notAllowed", label: "Ne pas utiliser les shortcodes de l'hôtel" },
  // ];
// Translation

    const { t, i18n } = useTranslation();

    const languages = [
      { value: "fr", text: "Français", img: DrapeauAngleterre },
      { value: "en", text: "English", img: DrapeauFrance },
    ];
    const [selectedLang, setLang] = useState(languages[0]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleChangeLangue = (selectedLang) => {
    setLang(selectedLang);
    i18n.changeLanguage(selectedLang.value);
    setDropdownOpen(false);
  };


  const optionsStatut = [
    { value: "Prés_stay", label: "Prés_stay" },
    { value: "Post_stay", label: "Post_stay" },
    { value: "In_stay", label: "In_stay" },
  ];

  useEffect(() => {
    if (isUpdate) {
      const emailToEdit = emails.find((email) => idAsInt === email.id);
      if (emailToEdit) {
        setEmailEdit(emailToEdit);
        reset({
          description: emailToEdit.contenu,
          nom: emailToEdit.nom_email,
          sujet: emailToEdit.sujet_email,
          statut: emailToEdit.statut
        });
        setValue("description", emailToEdit.contenu);
        setEditorKey((prevKey) => prevKey + 1);
      }
    }
  }, [idAsInt, emails, reset, isUpdate]);

  return (
    <div className="pt-4 margin-top-negative">
      <div className="d-flex justify-content-end">
          {/* <Button type="submit" background="#FFC857" color="#000" borderColor="none">{isUpdate ? "Modifier et quitter" : "Sauvegarder et quitter"}</Button> */}
          <Button type="button" className="rounded-5 px-3 " background="#FFF" color="#FFC857" borderColor="1px solid #FFC857" onClick={switchEmail}>Annuler</Button>
          <Button type="submit" className="rounded-5 px-3" background="#FFC857" color="#000000" borderColor="1px solid #FFC857">{isUpdate ? "Modifier" : "Sauvegarder"}</Button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column" style={{ width: "100%" }}>
        <div className="d-flex flex-column">
        <Label htmlFor="sujet">Nom</Label>
        <Input {...register("nom")} className="p-2 my-3" type="text" placeholder="Nom" />
        {errors.nom && <p style={{ color: "red" }}>{errors.nom.message}</p>}
        </div>
        <div className="row align-items-center">
          <div className="col-lg-10 col-md-12">
            <Label htmlFor="sujet">Sujet</Label>
            <Input {...register("sujet", { required: t("errorRequired"),
            minLength: { value: 3, message: t("errorMinLength") }
            })}
            className="p-2 my-3 form-control" type="text" placeholder={t("placeholderName")} id="sujet"/>
            {errors.sujet && <p style={{ color: "red" }}>{errors.sujet.message}</p>}
          </div>
          <div className="col-lg-2 col-md-12">
          <Label>Langue</Label>
            <div className="custom-select" onClick={() => setDropdownOpen(!isDropdownOpen)}>
              <div className="selected-option">
                {/* <img src={selectedLang.img} alt={selectedLang.text} className="w-25" /> */}
                {selectedLang.text}
              </div>
              {isDropdownOpen && (
                <ul className="dropdown">
                  {languages.map((item) => (
                    <li key={item.value} onClick={() => handleChangeLangue(item)} className="d-flex align-items-center gap-2">
                      <img src={item.img} alt={item.text} className="w-25 h-25"/>
                      <span>{item.text}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        {/* <div className="d-flex">
          <DivSignePlus className="mt-1 me-2">+</DivSignePlus>
          <Label style={{ color: "#FFC857" }}>Ajouter une shortcode</Label>
        </div> */}
        <Controller
          name="statut"
          control={control}
          render={({ field }) => (
            <Checkbox
              name="statut"
              {...field}
              flexDirection="row"
              options={optionsStatut	}
              onChange={(value) => field.onChange(value)}
              my="my-3"
              me="me-5"
            />
          )}
        />
        {errors.statut	 && <p style={{ color: "red" }}>{errors.statut	.message}</p>}

        <Label className="mb-2">Réponse préparée</Label>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Editor
              {...field}
              key={editorKey}
              value={field.value}
              onTextChange={(e) => {
                field.onChange(e.htmlValue);
              }}
              headerTemplate={header}
              style={{ height: "150px" }}
            />
          )}
        />
        {errors.description && <p style={{ color: "red" }}>{errors.description.message}</p>}

      </form>
            <ToastContainer className="mt-5" />
    </div>
  );
};

const NouvelleEmail = () => (
  <EmailProvider>
    <NouvelleEmailForm />
  </EmailProvider>
);

export default NouvelleEmail;
