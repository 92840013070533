import { ApiMananger } from "actions/rtkquery";
import { textePrepare } from "routes/api/endpoints";

export const textPrepareMutation = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    addTextePrepare: builder.mutation({
      query: (textePrepareData) => ({
        url: textePrepare.addTextePrepare,
        method: "POST",
        body: textePrepareData,
      }),
    }),
    deleteTextePrepare: builder.mutation({
      query: (id) => ({
        url: textePrepare.deleteTextePrepare.replace(":id", id),
        method: "DELETE",
      }),
    }),
    updateTextePrepare: builder.mutation({
      query: ({ id, textePrepareData }) => ({
        url: textePrepare.deleteTextePrepare.replace(":id", id),
        method: "PUT",
        body: textePrepareData,
      }),
    }),
    sendMail: builder.mutation({
      query: (mailData) => ({
        url: textePrepare.sendEmail,
        method: "POST",
        body: mailData,
      }),
    }),
  }),
});

export const {
  useDeleteTextePrepareMutation,
  useAddTextePrepareMutation,
  useSendMailMutation,
  useUpdateTextePrepareMutation,
} = textPrepareMutation;
