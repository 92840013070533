import React, { useState } from "react";
import BasicTable from "components/RelationClientDetail/ServiceCommander/Tableau/BasicTable";
import CustomSelect from "components/CustomComponent/Select/CustomSelect";
import "./ServiceCommande.css";
import { IoMdLogOut } from "react-icons/io";
import CustomButton from "components/CustomComponent/Boutton/CustomButton";
import InputSearch from "components/CustomComponent/SearchInput/InputSearch";
import { useGetAllServicesQuery } from "actions/service/query";
import { PiCalendarBold } from "react-icons/pi";
import { useSearchParams } from "react-router-dom";
import {
  InputSelectDate,
  ContainSelectData, SpanIcon
} from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import { formatDate } from "utils/FormatDate";
import { useGetHotelQuery } from "actions/hotel/queries";

const ServiceCommande = () => {
  const [valueSearch, setValueSearch] = useState("");
  // Id hotelier connecté
  const { data: hotel } = useGetHotelQuery();
  const hotelId = hotel?.id;
  const { data: getServicecommandes } = useGetAllServicesQuery({ hotelId });
  // console.log(getServicecommandes?.data);
  const [searchParams, setSearchParams] = useSearchParams();
  const filterDate = searchParams.get("dateDebut") || "";
  const getStatusInFrench = (status) => {
    switch (status) {
      case "urgent":
        return "À traiter en urgence";
      case "treat":
        return "À traiter";
      case "pending":
        return "En attente";
      case "treated":
        return "Traité";
      default:
        return "Inconnu";
    }
  };

  console.log(getServicecommandes?.data)

  const formattedData =
    getServicecommandes?.data?.map((serviceCommande) => {
      const statusInFrench = getStatusInFrench(serviceCommande.status);
      return {
        demandedAt: new Date(
          serviceCommande?.voyageur?.created_at
        ).toLocaleString("fr-FR"), // Format the date
        accessoires: serviceCommande.service?.title || "Service non spécifié", // Extract service title
        voyageur: `${serviceCommande.voyageur?.prenom} ${serviceCommande.voyageur?.nom}`, // Combine first name and last name
        period: new Date(serviceCommande.date_naissance).toLocaleDateString(
          "fr-FR"
        ), // Format the date
        status: statusInFrench, // Use the status from the API, translated
        id: serviceCommande?.id
      };
    }) || [];

  const handleFilterChange = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
    setSearchParams(newSearchParams);
  }
  const filteredData = formattedData.filter((row) =>
    row.voyageur.toLowerCase().includes(valueSearch.toLowerCase()) &&
    (!filterDate || row.date_debut_sejour.toLowerCase().includes(filterDate.toLowerCase())) && row.status !== "treated" && row.status !== "Traité"
  );
  const options = [
    { value: "", label: "Statut" },
    { value: "option1", label: "En attente" },
    { value: "option2", label: "À traiter en urgence" },
    { value: "option3", label: "À traiter" },
  ];
  const uniqueDates = [...new Set(formattedData.map((item) => item.date_debut_sejour))];

  return (
    <div
      className="row card"
      style={{
        border: "none",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        margin: 0,
        padding: "15px",
      }}>
      <div className="card-header border-0 pb-3 m-0 p-0 mb-3 align-items-center d-flex justify-content-between">
        <div
          className="d-flex gap-2 align-items-center">
          <div
            className="d-flex gap-2 align-items-center"
            style={{ fontFamily: "Roboto", fontSize: "16px", fontWeight: 500 }}>
            <span
              style={{
                color: "#212529",
              }}>
              Services commandés
            </span>
            <span
              style={{
                color: "#949494",
              }}>
              ({getServicecommandes?.data.length})
            </span>
          </div>
          <div
            className="d-flex gap-2 align-items-center "
            style={{ fontSize: "14px" }}>
            <span
              style={{
                color: "#212529",
                fontWeight: 500,
                fontSize: "14px",
                fontFamily: "Roboto",
              }}>
              Filtrer par
            </span>
            <ContainSelectData>
              <InputSelectDate
                value={filterDate}
                onChange={(e) => handleFilterChange("dateDebut", e.target.value)}
              >
                <option value="">jj/mm/aa</option>
                {uniqueDates.map((date, index) => (
                  <option key={index} value={date}>
                    {formatDate(date)}
                  </option>
                ))}
              </InputSelectDate>
              <SpanIcon>
                <PiCalendarBold className="" />
              </SpanIcon>
            </ContainSelectData>
            <CustomSelect
              className="custom-select"
              backgroundColor="#fff"
              color="#000000"
              border="1px solid #FFC857"
              padding={"8px 10px"}
              borderRadius="38px"
              options={options}
            />
            <InputSearch
              classNSearchInput="border border-warning"
              value={valueSearch}
              onChange={(e) => setValueSearch(e.target.value)}
              placeholder="Rechercher par nom"
              classInput={"outline-none border-none custom-input"}
            />
          </div>
        </div>
        <div className="d-flex gap-2">
          <CustomButton
            className="d-flex align-items-center gap-1 text-dark fw-bold"
            borderRadius="30px"
            color={"black"}
            border="1px solid #FFC857"
            backgroundColor="#fff"
            padding={"6px 17px"}
            fontSize={"14px"}>
            Extraire la liste
            <IoMdLogOut className="" />
          </CustomButton>
          <CustomButton
            className="d-flex align-items-center gap-1 text-dark fw-bold"
            borderRadius="30px"
            color={"black"}
            border="1px solid #FFC857"
            backgroundColor="#FFC857"
            padding={"6px 17px"}
            fontSize={"14px"}>
            Éléments traités
          </CustomButton>
        </div>
      </div>
      <div
        className="card-body p-0 m-0"
        style={{ width: "100%", overflow: "hidden" }}>
        <BasicTable data={filteredData} />
      </div>
    </div>
  );
};

export default ServiceCommande;
