import styled from "styled-components";

export const NavbarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  background-color: #ffffff !important;
  position: fixed;
  z-index: 1000;
  height: 55px;
  border: none;
  margin: 0;
  width: ${({ width }) => width};
  margin-top: 15px;
`;

export const ImgSearchInput = `
  data:image/svg+xml,
  %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E
    %3Cpath d='M15.5 14h-.79l-.28-.27a6.5 6.5 0 1 0-.7.7l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 
    0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' fill='%23DDDDDD' /%3E
  %3C/svg%3E
`;

export const SearchInput = styled.div`
  font-size: 12px;
  padding: 12px 9px 12px 12px;
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 180px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;

  ::placeholder {
    color: gray;
  }

  @media (max-width: 882px) {
    width: 150px;
  }
  @media (max-width: 436px) {
    width: 50px;
  }
`;
export const SearchIcone = styled.span`
  color: gray;
  font-size: 14px;
`;
export const InputVoyage = styled.input`
  width: 140px;
  height: 16px;
  padding: 10px 5px;
  border: none;
`;
export const Input = styled.input`
  width: 140px;
  height: 15px;
  padding: 10px 5px;
  border: none;

  ::placeholder {
    color: #000000;
  }
`;
