import React from "react";
import { Button, Icone } from "components/FormulairePreStay/StyledFormPreStay";

function Bouton({ onClick, name, icon, type }) {
  return (
    <Button onClick={onClick} type={type}>
      <span>{name}</span>
      <Icone>{icon}</Icone>
    </Button>
  );
}

export default Bouton;
