import React, { useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import Header from "components/GestionEnvoie/Hearder";
import AboutYouForm from "./TabsFormPreStay/AboutYouForm";
import ServicePage from "./TabsFormPreStay/ServicePage";
import Sejour from "./TabsFormPreStay/Sejour";
import Validation from "./TabsFormPreStay/Validation";
import GestionEnvoiProvider from "./GestionEnvoiProvider";
import { useForm, FormProvider } from "react-hook-form";
import ValidForm from "./TabsFormPreStay/ValidForm";
import { toast } from "react-toastify";
import { useAddDataFormMutation } from "actions/service/mutation";

const FormPreStay = () => {
  const methods = useForm();
  const { getValues } = methods;
  const { voyageurId } = useParams();
  const [addDataForm, { isLoading }] = useAddDataFormMutation();

  const [searchParams, setSearchParams] = useSearchParams();
  const activeKeyFromUrl = searchParams.get("step") || "01";
  const [activeKey, setActiveKey] = React.useState(activeKeyFromUrl);
  useEffect(() => {
    setSearchParams({ step: activeKey });
  }, [activeKey, setSearchParams]);

  const headerData = [
    { nombre: "01", titre: "A propos de vous" },
    { nombre: "02", titre: "Votre séjour" },
    { nombre: "03", titre: "Les services" },
    { nombre: "04", titre: "Validation" },
  ];

  const onSubmit = async () => {
    const formData = getValues();
    formData.voyageur = parseInt(voyageurId);
    // console.log({ voyageurId });

    // console.log("Form data on submit:", formData); // Log the entire form data
    try {
      const result = await addDataForm(formData).unwrap();
      console.log("Form data submitted successfully:", result);
      toast.success("Données du formulaire soumises avec succès");
      setActiveKey("05");
    } catch (err) {
      toast.error("Error lors de la soumission du formulaire:", err);
      // console.error("Error submitting form data:", err);
    }
  };

  const handlePrevious = () => {
    if (parseInt(activeKey) > 1) {
      setActiveKey((prev) => (parseInt(prev) - 1).toString().padStart(2, "0"));
    }
  };

  const handleNext = () => {
    if (parseInt(activeKey) < headerData.length) {
      setActiveKey((prev) => (parseInt(prev) + 1).toString().padStart(2, "0"));
    }
  };

  return (
    <div className="overflow-x-hidden">
      <div className="d-flex flex-column justify-content-center p-3">
        <div className="mt-4">
          <div className="d-flex justify-content-center">
            {headerData.map((data, index) => (
              <div key={index} onClick={() => setActiveKey(data.nombre)}>
                <Header
                  nombre={data.nombre}
                  titre={data.titre}
                  isActive={activeKey === data.nombre}
                />
              </div>
            ))}
          </div>

          <GestionEnvoiProvider>
            <FormProvider {...methods}>
              <form
                className="tab-content my-2"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                {activeKey === "01" && <AboutYouForm onClick={handleNext} />}
                {activeKey === "02" && (
                  <Sejour
                    handleCancel={handlePrevious}
                    handleClick={handleNext}
                  />
                )}
                {activeKey === "03" && (
                  <ServicePage
                    handleCancel={handlePrevious}
                    onClick={handleNext}
                  />
                )}
                {activeKey === "04" && (
                  <Validation
                    handleCancel={handlePrevious}
                    isLoading={isLoading}
                  />
                )}
                {activeKey === "05" && (
                  <ValidForm handleCancel={handlePrevious} />
                )}
              </form>
            </FormProvider>
          </GestionEnvoiProvider>
        </div>
      </div>
    </div>
  );
};

export default FormPreStay;
