// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importation des fichiers de traduction
import enTranslations from "./components/Langues/enTranslations";
import frTranslations from "./components/Langues/frTranslations"


i18n
  .use(initReactI18next)
  .init({
    resources: {
      fr: {
        translation: frTranslations,
      },
      en: {
        translation: enTranslations,
      },
    },
    lng: "fr", // Langue par défaut
    fallbackLng: "fr", // Langue de secours
    interpolation: {
      escapeValue: false, // Pas nécessaire pour React
    },
  });

export default i18n;
