import React from "react";
import { NeumorphicButton, SmallButton } from "components/FormulairePreStay/StyledFormPreStay"

const BoutonNeomorphisme = ({ onClick, active, type }) => {
    return (
        <NeumorphicButton onClick={onClick} active={active} type={type}>
            <SmallButton />
        </NeumorphicButton>
    );
};

export default BoutonNeomorphisme;
