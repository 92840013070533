import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const emailSchema = yup.object().shape({
    nom: yup.string().required("Nom est requis."),
    statut: yup.string().required("Le statut est obligatoire est requis."),
    sujet: yup.string().required("Le sujet est obligatoire est requis."),
    description: yup.string().required("La description est obligatoire."),
});
const EmailProvider = ({ children }) => {
  const methods = useForm({
    resolver: yupResolver(emailSchema)
  });

  return (
    <FormProvider {...methods}>{ children }</FormProvider>
  );
};

export default EmailProvider;