import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  useGetReservationByIdQuery,
  useGetReservationsQuery,
} from "actions/reservation/query";
import {
  useAddNewReservationMutation,
  useUpdateVoyageurReservationsMutation,
} from "actions/reservation/mutation";
import { useAddNewVoyageurReservationMutation } from "actions/voyageur/mutation";
import { addNewVoyageurReservation } from "redux/features/userSliceWithTokenValidation";
import { useGetHotelQuery } from "actions/hotel/queries";
import { useGetVoyageursQuery } from "actions/voyageur/query";
import toast from "react-hot-toast";

export const useVoyageurReservation = (id) => {
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();
  const { data: hotel } = useGetHotelQuery();
  const userId = hotel?.id;
  const { refetch } = useGetReservationsQuery(userId);
  const { data: reservation } = useGetReservationByIdQuery(
    id ? parseInt(id, 10) : undefined
  );
  const { data: voyageursData, refetch: refetchVoyageurs } =
    useGetVoyageursQuery();
  const voyageurs = voyageursData || [];

  const [addNewVoyageurReservationMutation] =
    useAddNewVoyageurReservationMutation();
  const [addNewReservationMutation] = useAddNewReservationMutation();
  const [updateVoyageurReservationsMutation] =
    useUpdateVoyageurReservationsMutation();

  const handleSubmitVoyageurReservation = async () => {
    const formData = getValues();
    try {
      let data;
      if (id && reservation) {
        // Update logic
        data = await updateVoyageurReservationsMutation({
          id: reservation.id,
          voyageurReservationData: createVoyageurReservationData(
            formData,
            userId
          ),
        });
        toast.success("Voyageur et réservation mise à jour avec succès !");
      } else {
        // Create logic
        if (formData.voyageur) {
          const existingVoyageur = voyageurs.find(
            (v) => v.id === formData.voyageur.id
          );
          if (existingVoyageur) {
            data = await addNewReservationMutation(
              createReservationData(formData, userId, existingVoyageur.id)
            );
            toast.success("Réservation ajoutée avec succès !");
          }
        } else {
          const voyageurReservationData = createVoyageurReservationData(
            formData,
            userId
          );
          data = await addNewVoyageurReservationMutation(
            voyageurReservationData
          );
          dispatch(addNewVoyageurReservation(voyageurReservationData));
          toast.success("Voyageur et réservation ajoutés avec succès !");
        }
      }

      if (data.error) {
        throw new Error(JSON.stringify(data.error));
      }

      // console.log("Operation successful:", data);
      refetch();
      refetchVoyageurs();
      resetForm();
    } catch (error) {
      console.error("Erreur lors de l'opération :", error);
      toast.error(`Erreur lors de l'opération : ${error.message}`);
    }
  };

  const createVoyageurReservationData = (formData, userId) => ({
    user: userId,
    identification: formData.selectedOption,
    numero_passport: formData.numero_passport,
    prenom: formData.prenom,
    nom: formData.nom,
    email: formData.email,
    telephone: formData.telephone,
    adresse: formData.adresse,
    date_naissance: formData.dateNaissance
      ? new Date(formData.dateNaissance).toISOString().split("T")[0]
      : null,
    genre: formData.genre,
    langue: formData.langue,
    chambres_associes: formData.chambres,
    nombre_person: formData.nombrePerson,
    // nom_reservation: formData.nomReservation,
    date_debut_sejour: formData.dateDebut,
    date_fin_sejour: formData.dateFin,
    statut_reservation: formData.statut,
    origin: formData.origine,
    demandes_speciales: formData.demandeSpeciale,
    type_client: formData.typedeClient,
    motif_voyage: formData.typeDeVoyage,
    status_paiement: formData.paiement,
  });

  const createReservationData = (formData, userId, voyageurId) => ({
    user: userId,
    voyageur: voyageurId,
    chambres_associes: formData.chambres.map((chambre) => ({ chambre })),
    // nom_reservation: formData.nomReservation,
    date_debut_sejour: formData.dateDebut,
    date_fin_sejour: formData.dateFin,
    nombre_person: formData.nombrePerson,
    statut_reservation: formData.statut,
    origin: formData.origine,
    demandes_speciales: formData.demandeSpeciale,
    typedeClient: formData.typedeClient,
    motif_voyage: formData.typeDeVoyage,
    status_paiement: formData.paiement,
  });

  const resetForm = () => {
    reset({
      selectedOption: "",
      numero_passport: "",
      prenom: "",
      nom: "",
      email: "",
      telephone: "",
      chambres: "",
      nomReservation: "",
      dateDebut: "",
      dateFin: "",
      statut: "",
      dateNaissance: "", // Add dateNaissance to reset
    });
  };

  useEffect(() => {
    if (id && reservation) {
      const dateDebut = new Date(reservation.date_debut_sejour);
      const dateFin = new Date(reservation.date_fin_sejour);
      const dateNaissance = reservation.voyageur_details.date_naissance
        ? new Date(reservation.voyageur_details.date_naissance)
            .toISOString()
            .split("T")[0]
        : "";

      reset({
        selectedOption: "passport",
        numero_passport: reservation.voyageur_details.numero_passport,
        prenom: reservation.voyageur_details.prenom,
        nom: reservation.voyageur_details.nom,
        email: reservation.voyageur_details.email,
        telephone: reservation.voyageur_details.telephone,
        dateNaissance: dateNaissance, // Map date_naissance to dateNaissance
        adresse: reservation.voyageur_details.adresse,
        langue: reservation.voyageur_details.langue,
        genre: reservation.voyageur_details.genre,
        dateDebut: dateDebut,
        dateFin: dateFin,
        chambres: reservation.chambres_associes.map(
          (chambre) => chambre.chambre
        ),
        nombrePerson: reservation.nombre_person,
        statut: reservation.statut_reservation,
        paiement: reservation.status_paiement,
        // nomReservation: reservation.nom_reservation,
        source: "Wave",
        origine: reservation.origin,
        typedeClient: reservation.type_client,
        typeDeVoyage: reservation.motif_voyage,
        demandeSpeciale: reservation.demandes_speciales,
      });
    }
  }, [reservation, reset, id]);

  return { handleSubmitVoyageurReservation, reservation };
};
