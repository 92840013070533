import React from "react"
import SidebarItem, { SidebarItemFiltre } from "../ATextesPrepares/SidebarItem";
import { enquete, messages } from "../UtilsSidebar";
import { TEXTESPREPARES, TEXTESPREPARES_NEW } from "routes/navigation/navigationPaths";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { SidebarItems, Titles } from "pages/Admin/StyledAdmin";

const Entete = styled.span`
 font-size: 16px;
 font-weight: 500;
 color: #000000;
font-family: Poppins;
`

function SidebarNewTextPrepare({ handleFilterChange, handleFilterDelete }) {
  const PATH_EMAIL = `/${TEXTESPREPARES}/${TEXTESPREPARES_NEW}`;
  const location = useLocation();
  return (
    <div className="px-3">
      <div className="row gap-1 px-2 ">
        <div className="mb-2">
          <Entete >Messages</Entete>
          {location.pathname === `${PATH_EMAIL}` ? messages.filter((items) => items.id === "email").map((item, index) => (
            <SidebarItem {...item} key={index} />
          )): messages.map((item, index) => (
            <SidebarItem handleFilterDelete={handleFilterDelete} {...item} key={index} />
          ))}
          <SidebarItems className="d-flex p-3 mb-2 mt-2 bg-transparent" >
          <div className="col-md-9 d-flex align-items-center">
              <span>+</span>
              <Titles className="">Ajouter un message</Titles>
          </div>
    </SidebarItems>
        </div>
        <div>
          <Entete >Périodes du séjour</Entete>
          {enquete.map((item, index) => (
            <SidebarItemFiltre {...item} key={index} handleFilterChange={handleFilterChange} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SidebarNewTextPrepare;