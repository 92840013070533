import { useGetHotelQuery } from "actions/hotel/queries";
import useGetCurrentUser from "hooks/useGetCurrentUser";
import React, { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "redux/features/userSliceWithTokenValidation";
import { LOGIN_PATH } from "routes/navigation/navigationPaths";

const AppContext = createContext({});

function AppProvider({ children }) {
  const { } = useGetCurrentUser();
  const { data, isLoading, error } = useGetHotelQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.persist?.auth?.accessToken);

  useEffect(() => {
    // If there is an error fetching the hotel data or if the token is missing
    if (error) {
      if (error.status === 401) {
        // Unauthorized, log out and redirect to login
        console.log("Unauthorized access - logging out.");
        dispatch(logout());
        navigate(LOGIN_PATH);
      } else {
        // Handle other types of errors if necessary
        console.error("An error occurred:", error);
      }
    } else if (!isLoading && !token) {
      // If not loading and no token, log out
      console.log("No token - logging out.");
      dispatch(logout());
      navigate(LOGIN_PATH);
    }
  }, [error, token, dispatch, navigate, isLoading]);

  return (
    <AppContext.Provider value={{ hotel: data || null, isLoading }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;

export const useGetHotel = () => useContext(AppContext);
