import React from "react";
import Traiter from "components/RelationClientDetail/Traiter/Traiter";
import CalendrierduClient from "../../../components/RelationClientDetail/CalendrierduClient/CalendrierduClient";
import ServiceCommande from "../../../components/RelationClientDetail/ServiceCommander/ServiceCommande";
import { styledRelationClientDetail } from "./RelationClientDetailPage/RelationClientDetailCss";
import { format } from "date-fns";
import { useSelector } from "react-redux";
// import { useGetReservationsQuery } from "actions/apiSliceRTK";
import styled from "styled-components";
import BreadCrumbs from "components/Breadcrumb/Breadcrumb";
import { useGetReservationsQuery } from "actions/reservation/query";

const Loader = () => (
  <div className="loader-container">
  <div className="loader"></div>
</div>
);

const FixedHeightCard = styled.div`
  height: 400px;
  overflow-x: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
const ContainerDemandeClient = styled.div`
  background: transparent;
  min-height: 100vh;
  max-height: 100%;
  width: 100%;
`;
const DashboardRelation = () => {
  // Id hotelier connecté
  const userId = useSelector((state) => state?.persist?.auth.user_id);
  const { data: reservationsData, isLoading } = useGetReservationsQuery(userId);
  const reservations = reservationsData?.data || [];
  // Fonction pour formater la date
  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };
  const dateNow = new Date();
    // Affichage du loader pendant le chargement
    if (isLoading) {
      return <Loader />;
    }
  return (
    <div style={styledRelationClientDetail.relationClient}>
      <div
        style={{
          padding: "19px 14px 15px 28px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "20px",
        }}>
        <BreadCrumbs isLoading={isLoading} />
        <ContainerDemandeClient className="d-flex flex-column">
          <div className="container-fluid">
            <div className="row pb-3">
              <div className="col-md-4 col-sm-12 p-0">
                <FixedHeightCard className="card" style={{}}>
                  <Traiter
                    reservations={reservations}
                    formatDate={formatDate}
                    dateNow={dateNow}
                  />
                </FixedHeightCard>
              </div>
              <div className="col-md-8 col-sm-12 ps-0 ps-md-3">
                <FixedHeightCard
                  className="card">
                  <CalendrierduClient
                    reservations={reservations}
                    formatDate={formatDate}
                    dateNow={dateNow}
                  />
                </FixedHeightCard>
              </div>
            </div>
            <div className="row p-0">
              <ServiceCommande />
            </div>
          </div>
          {/* <Traiter reservations={reservations} formatDate={formatDate} dateNow={dateNow} />
            <CalendrierduClient reservations={reservations} formatDate={formatDate} dateNow={dateNow} />
            <ServiceCommande /> */}
        </ContainerDemandeClient>
      </div>
    </div>
  );
};

export default DashboardRelation;
