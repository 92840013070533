// import ImageFond from "image/BgGroup.png"
import styled from "styled-components";
import FONTS from "utils/Variables/police";

const styledRelationClientDetail = {
  relationClient: {
    // backgroundColor: "#FFFFFF",
    // backgroundImage: `url("${ImageFond}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
    fontFamily: `${FONTS.Roboto} !important`,
    // maxHeight: "100vh !important",
  },
  contentTabs: {
    backgroundColor: "#FFF",
    // height: "20%",
    overflowY: "scroll",
    // maxHeight: "calc(100vh)",
  },
  detailClientTabsLi: {
    cursor: "pointer",
  },
  activetab: {
    color: "black",
  },
  messageClient: {
    background: "#F0F0F0",
  },
  statusHistorique: {
    backgroundColor: "#FFF2CF",
    color: "#FFB800",
  },
  arrowLeft: {
    position: "absolute",
    top: "50%",
    left: "-10px",
    transform: "translateY(-50%)",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderRight: "10px solid #fff",
  },
  textConversation: {
    fontSize: "10px !important",
    margin: "0",
    padding: "0",
  },
  borderTextConversation: {
    borderBottom: "2px solid #655573",
    display: "inline-block",
    color: "#655573",
    fontSize: "26px",
    fontWeight: 400,
    fontFamily: "Roboto",
    lineHeight: "50px",
  },
};

export { styledRelationClientDetail };

export const LinkBreadCrumb = styled.a`
  color: #000;
`;
