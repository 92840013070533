import React, { useState } from "react";
import CustomButton from "components/CustomComponent/Boutton/CustomButton";
import { HiOutlineArrowPath } from "react-icons/hi2";
import { MdOutlineFileDownload } from "react-icons/md";
import { PiPlusLight } from "react-icons/pi"
import CadreTableau from "components/GestionVoyageur/CadreTableau";
import {
  SearchInput,
  InputVoyage,
  SearchIcone,
} from "components/HeaderListe/Navbarcss";
import { Link, useSearchParams } from "react-router-dom";
import ImportModal from "./ImportModal";
import {
  CadreHeader,
  CadreHeader2,
  GrpBtn,
  HeaderTexte,
  Icone,
  Navig,
  SelectGVoyag,
  TextBut,
} from "./GestionVoyageurCss";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import {
  ContainerDemandeClient, InputSelectDate,
  ContainSelectData, SpanIcon,
  ContainerGestion
} from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import { PiCalendarBold } from "react-icons/pi";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import api from "routes/api/global-vars";
import { voyageurEndpoint } from "routes/api/endpoints";
import { formatDate } from "utils/FormatDate";
import noeud from "image/noeud.png";
import "./input.css";
import BreadcumbAdmin from "./BreadcumpAdmin/BreadcumbAdmin";
import { FaSearch } from "react-icons/fa";
import { useGetReservationsQuery } from "actions/reservation/query"
import { useImporterDonneesVoyageurMutation } from "actions/voyageur/mutation";
import { Pagination } from "utils/Pagination";
import { GrInbox } from "react-icons/gr";
import Loader from "components/Loader/Loader";

const GestionVoyageur = () => {
  const typeVoyageOptions = ["Loisir", "Corporate"];
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showTableBody, setShowTableBody] = useState(false);

  const { data: reservation, refetch, isLoading } = useGetReservationsQuery();
  const reserver = reservation?.data || [];
  const [searchParams, setSearchParams] = useSearchParams();
  const filterDateDebut = searchParams.get("dateDebut") || "";
  const filterDateFin = searchParams.get("dateFin") || "";
  const filterVoyage = searchParams.get("voyage") || "";
  const search = searchParams.get("search") || "";
  const sortedReservationVoyageurs = reserver.length > 0
    ? [...reserver].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Crée une copie avant de trier
    : [];
  const filteredReservationVoyageurs = sortedReservationVoyageurs.filter((item) => {
    return (
      (!search || item.voyageur_details.nom.toLowerCase().includes(search.toLowerCase())) &&
      (!filterDateDebut || item.date_debut_sejour.toLowerCase().includes(filterDateDebut.toLowerCase())) &&
      (!filterDateFin || item.date_fin_sejour === filterDateFin) &&
      (!filterVoyage || item.motif_voyage.toLowerCase().includes(filterVoyage.toLowerCase()))
    );
  });
  const handleFilterChange = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
    setSearchParams(newSearchParams);
  }
  const page = parseInt(searchParams.get("page")) || 1;
  const limit = parseInt(searchParams.get("limit")) || 5;
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedHotels = filteredReservationVoyageurs.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredReservationVoyageurs.length / limit);
  const handlePageChange = (newPage) => {
    handleFilterChange("page", newPage);
  };
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const toggleTableBody = () => {
    setShowTableBody(!showTableBody);
  };
  const [importerDonneesVoyageurMutation] =
    useImporterDonneesVoyageurMutation();
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post(
        `${api}${voyageurEndpoint.importerDonneesVoyageur}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await importerDonneesVoyageurMutation().unwrap();
      refetch();
      toast.success("Voyageur ajouté avec succès!");
      // console.log("Voyageur ajouté avec succès!", response.data);
    } catch (error) {
      toast.error("Erreur lors de l'ajout du voyageur");
      console.error("Erreur lors de l'ajout du voyageur :", error);
    }
    closeModal();
  };
  if (isLoading) return <Loader/>;

  return (
    <>
      <div style={styledRelationClientDetail.relationClient}>
        <ContainerGestion
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <BreadcumbAdmin label={"Base voyageurs"} />
          <ContainerDemandeClient className="px-4 py-3">
            <ContainerDemandeClient
              className="container-fluid"
              style={{ backgroundColor: "white" }}
            >
              <CadreHeader>
                <Navig>
                  <div>
                    <HeaderTexte style={{ color: "#008DF3" }}>
                      Admin
                    </HeaderTexte>
                    <HeaderTexte>
                      / Voyageurs {reserver.length}
                    </HeaderTexte>
                  </div>
                </Navig>
                <SearchInput style={{ border: "1px solid #78828C21" }}>
                  <SearchIcone>
                    {" "}
                    <FaSearch />
                  </SearchIcone>
                  <InputVoyage
                    value={search}
                    onChange={(e) => handleFilterChange("search", e.target.value)}
                    placeholder="Rechercher un voyageur"
                    style={{
                      color: "#55595C",
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  />
                </SearchInput>
                <GrpBtn>
                  <Link onClick={toggleTableBody}>
                    <CustomButton
                      className="d-flex align-items-center justify-content-center"
                      width="160px"
                      height="30px"
                      borderRadius="30px"
                      border="1px solid #FFC857"
                      backgroundColor="white"
                    >
                      <Icone>
                        {" "}
                        <HiOutlineArrowPath />
                      </Icone>
                      <TextBut className="">Synchronisation</TextBut>
                    </CustomButton>
                  </Link>
                  <Link onClick={openModal}>
                    <CustomButton
                      className="d-flex justify-content-center align-items-center"
                      width="180px"
                      height="30px"
                      borderRadius="30px"
                      border="1px solid #FFC857"
                      backgroundColor="white"
                    >
                      <Icone>
                        {" "}
                        <MdOutlineFileDownload />{" "}
                      </Icone>
                      <TextBut>Importer Excel/CSV</TextBut>
                    </CustomButton>
                  </Link>
                  <Link to="nouvau-voyageur">
                    <CustomButton
                      className="d-flex justify-content-center align-items-center"
                      width="180px"
                      height="30px"
                      borderRadius="30px"
                      border="1px solid #FFC857"
                      backgroundColor="#FFC857"
                    >
                      <Icone>
                        {" "}
                        <PiPlusLight style={{ color: "#000000" }} />{" "}
                      </Icone>
                      <TextBut style={{ color: "#000000" }} className="ps-1">
                        Nouveau voyageur
                      </TextBut>
                    </CustomButton>
                  </Link>
                </GrpBtn>
              </CadreHeader>
              <CadreHeader2 className="d-lg-flex justify-content-between align-items-center d-sm-block">
                <div
                  style={{
                    fontWeight: "500",
                    color: "#000000",
                    fontFamily: "Roboto",
                    lineHeight: "32px",
                  }}
                  className=""
                >
                  Filtrer par
                </div>
                <div className="col-9 d-flex gap-3 containSelect">
                  <ContainSelectData>
                    <InputSelectDate
                      value={filterDateDebut}
                      onChange={(e) => handleFilterChange("dateDebut", e.target.value)}
                    >
                      <option value="">Date début de séjour</option>
                      {reserver.map((item) => (
                        <option key={item.id} value={item.date_debut_sejour}>
                          {formatDate(item.date_debut_sejour)}
                        </option>
                      ))}
                    </InputSelectDate>
                    <SpanIcon>
                      <PiCalendarBold className="" />
                    </SpanIcon>
                  </ContainSelectData>
                  <ContainSelectData>
                    <InputSelectDate
                      value={filterDateFin}
                      onChange={(e) => handleFilterChange("dateFin", e.target.value)}
                    >
                      <option value="">Date fin de séjour</option>
                      {reserver.map((item) => (
                        <option key={item.id} value={item.date_fin_sejour}>
                          {formatDate(item.date_fin_sejour)}
                        </option>
                      ))}
                    </InputSelectDate>
                    <SpanIcon>
                      <PiCalendarBold className="" />
                    </SpanIcon>
                  </ContainSelectData>
                  <SelectGVoyag
                    style={{
                      border: "1px solid #FFC857",
                      backgroundColor: "white",
                    }}
                    value={filterVoyage}
                    onChange={(e) => handleFilterChange("voyage", e.target.value)}
                  >
                    <option value="">
                      Type de voyage
                    </option>
                    {typeVoyageOptions.map((status, index) => (
                      <option key={index} value={status.motif_voyage}>
                        {status}
                      </option>
                    ))}
                  </SelectGVoyag>
                </div>
                <CustomButton
                  className="d-flex align-items-center justify-content-center gap-1  me-end"
                  fontWeight="500"
                  color="#000000"
                  borderRadius="30px"
                  border="1px solid gray"
                  backgroundColor="white"
                  fontSize="12px"
                >
                  <TextBut
                    className=""
                    style={{
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                      lineHeight: "18px",
                    }}
                  >
                    Fusionner les profils
                  </TextBut>
                  <img src={noeud} alt="n" style={{ width: "16px" }} />
                </CustomButton>
              </CadreHeader2>
              {
                paginatedHotels.length > 0 ? (
                  <>
                    <CadreTableau
                      showTableBody={showTableBody}
                      formatDate={formatDate}
                      refetch={refetch}
                      data={paginatedHotels}
                    />
                    <Pagination totalPages={totalPages} currentPage={page} onPageChange={handlePageChange} />
                  </>
                ) : (
                  <>
                    <table
                      className="mt-2 table table-hoverd"
                      style={{
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        verticalAlign: "middle",
                        marginLeft: "-20px",
                      }}
                    >
                      <thead className="table-light" style={{ color: "#555555" }}>
                        <tr style={{}} className=" align-middle">
                          <th scope="col" className="py-2 px-4">
                            {" "}
                            <input type="checkbox" />
                          </th>
                          <th scope="col">Numéro Voyageur</th>
                          <th scope="col">Prénom et nom</th>
                          <th scope="col">Date de mise à jour du profil</th>
                          <th scope="col">E-mail</th>
                          <th scope="col">Date de naissance</th>
                          <th scope="col">Type client</th>
                          <th scope="col">Type de voyage</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="p-5">
                      <div className="p-5 d-flex flex-column align-items-center">
                        <GrInbox style={{ color: "#DFDFDF", fontSize: "38px" }} />
                        <p
                          style={{
                            color: "#BFBFBF",
                            fontSize: "15.30px",
                            fontWeight: 500,
                            fontFamily: "Poppins",
                            marginTop: "10px",
                          }}>
                          Aucune donnée
                        </p>
                      </div>
                    </div>
                  </>
                )
              }
              <ImportModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                handleFileChange={handleFileChange}
              />
              {/* <ToastContainer className="mt-5" /> */}
            </ContainerDemandeClient>
          </ContainerDemandeClient>
        </ContainerGestion>
      </div>
    </>
  );
};

export default GestionVoyageur;
