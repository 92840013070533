import React from "react";
import { Img } from "./InfoClientCSS";

export default function InfoPersonnel({ label, img, ms, valeur, classCont, classimg, width, height }) {
  return (
    <div style={{ fontFamily: "Roboto", fontSize: "14px", color: "#272727", fontWeight: 400, lineHeight: "20px" }} className={classCont}>
      <p style={{ fontSize: "14px" }} className={ms}>{label}</p>
      <p >
      <span >
        {
          img ? <Img src={img} alt="" className={classimg} width={width} height={height} style={{ width: "25px", height: "25px" }} /> : null
        }
         {valeur}
      </span>
      </p>
    </div>
  );
}
