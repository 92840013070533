// import React, { useState } from "react";
// import { useMemo } from "react";
// // import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
// import useWindowDimensions from "../hooks/useWindowsDimention";
// import styled from "styled-components";
// import { useSelector } from "react-redux";

// const Container = styled.div`
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
// `;
// const NavbarPaner = styled.div`
//   flex: 1;max-width: 1140px;
//   position: fixed;
//   top: 0;
//   width: ${({ navbarPanerWidth }) => navbarPanerWidth};
//   height: auto;
//   z-index: 4;
// `;

// const SidebarPaner = styled.div`
//   height: 100vh;
//   width: ${({ sidebarWidth }) => sidebarWidth};
// `;
// const BodyContainer = styled.div`
//   display: flex;
// `;

// const ContaintOutlet = styled.div`
//   width: ${({ outletWidth }) => outletWidth};
//   background-color: #F3F3F3;
//   transition: padding 0.3s;
//   overflow-x: hidden;
//   z-index: 1;
//   padding: ${({ openedMenu, minViewPort }) =>
//     openedMenu ?
//       " 0px 0 40px" :
//       minViewPort ?
//         "0px 10px 0 0px" :
//         "40px 0px 0 0px"};
// `;

// const SpliTemplateScreen = ({ children }) => {
//   const [openedMenu, setOpenedMenu] = useState(false);
//   const [minViewPort, setMinViewPort] = useState(false);

//   const sidebarWidth = useSelector((state) => state.sidebarWidth.width);
//   const navbarPanerWidth = useSelector((state) => state.sidebarWidth.navbarPanerWidth);
//   const outletWidth = useSelector((state) => state.sidebarWidth.outletWidth);

//   const [sidebar, navbar, outlet] = children;

//   const { width } = useWindowDimensions();

//   useMemo(() => {
//     if (width <= 900) {
//       setMinViewPort(true);
//       setOpenedMenu(true);
//     } else {
//       setMinViewPort(false);
//       setOpenedMenu(false);
//     }
//   }, [width]);

//   return (
//         <Container>
//           <NavbarPaner navbarPanerWidth={navbarPanerWidth}>
//             {navbar}
//           </NavbarPaner>
//           <BodyContainer>
//             <SidebarPaner sidebarWidth={sidebarWidth}>
//               {sidebar}
//             </SidebarPaner>
//             <ContaintOutlet outletWidth={outletWidth} openedMenu={openedMenu} minViewPort={minViewPort}>
//               {outlet}
//             </ContaintOutlet>
//           </BodyContainer>
//         </Container>
//   );
// };

// export default SpliTemplateScreen;

import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useWindowDimensions from "../hooks/useWindowsDimention";

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-between;
`;

const SidebarPaner = styled.div`
  height: 100vh;
  width: ${({ sidebarWidth }) => sidebarWidth};
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ rightContainerWidth }) => rightContainerWidth};
`;

const NavbarPaner = styled.div`
  // flex: 1;
  width: 100%;
  height: auto;
  z-index: 4;
`;

const ContaintOutlet = styled.div`
  flex: 1;
  width: 100%;
  background-color: #f3f3f3;
  transition: padding 0.3s;
  overflow-x: hidden;
  z-index: 1;
  padding: ${({ openedMenu, minViewPort }) =>
    openedMenu ? "0px 0 0 40px" : minViewPort ? "0px 10px 0 0px" : "0px 0px 0 0px"};
`;

const SpliTemplateScreen = ({ children }) => {
  const [openedMenu, setOpenedMenu] = useState(false);
  const [minViewPort, setMinViewPort] = useState(false);

  const sidebarWidth = useSelector((state) => state?.persist?.sidebarWidth.width);
  const rightContainerWidth = `calc(100% - ${sidebarWidth})`;

  const [sidebar, navbar, outlet] = children;

  const { width } = useWindowDimensions();

  useMemo(() => {
    if (width <= 900) {
      setMinViewPort(true);
      setOpenedMenu(true);
    } else {
      setMinViewPort(false);
      setOpenedMenu(false);
    }
  }, [width]);

  return (
    <Container>
      <SidebarPaner sidebarWidth={sidebarWidth}>{sidebar}</SidebarPaner>
      <RightContainer rightContainerWidth={rightContainerWidth}>
        <NavbarPaner>{navbar}</NavbarPaner>
        <ContaintOutlet openedMenu={openedMenu} minViewPort={minViewPort}>
          {outlet}
        </ContaintOutlet>
      </RightContainer>
    </Container>
  );
};

export default SpliTemplateScreen;
