import React from "react"
import VoyageurProvider from "components/AdminContain/NouveauVoyageur/Provider/VoyageurProvider"
import NouvelleReservation from "./NouvelleReservation"

const NouvelleReservationPage = () => {
  return (
      <VoyageurProvider>
        <NouvelleReservation />
      </VoyageurProvider>
  )
}

export default NouvelleReservationPage