import React, { useEffect, useRef, useState } from "react";
import DrapeauSenegal from "image/DrapeauSenegal.jpg";
import InfoPersonnel from "components/RelationClientDetail/InfoClient/InfoPersonnel";
import { CadreTab } from "pages/Admin/DashboardAdmin/GestionVoyageur/GestionVoyageurCss";
import { FaEye, FaChevronDown } from "react-icons/fa";
import { LuAlarmClock } from "react-icons/lu";
import "./style.css";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useUpdateServiceMutation } from "actions/service/mutation";
import { toast, ToastContainer } from "react-toastify";
import { GrInbox } from "react-icons/gr";
import { Pagination } from "utils/Pagination";
import { useSearchParams } from "react-router-dom"

const BasicTable = ({ data }) => {
  const statuses = ["À traiter en urgence", "À traiter", "En attente"];
  const [searchParams, setSearchParams] = useSearchParams();
  const getInitialColors = () => {
    const savedColors = localStorage.getItem("iconColors");
    if (savedColors) {
      return JSON.parse(savedColors);
    } else {
      return data.map(() => "green");
    }
  };
  const [iconColors, setIconColors] = useState(getInitialColors);
  const [tableData, setTableData] = useState(data);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({});
  const [updateService] = useUpdateServiceMutation();
  useEffect(() => {
    localStorage.setItem("iconColors", JSON.stringify(iconColors));
  }, [iconColors]);
  const handleSetTreated = async (index) => {
    const serviceId = tableData[index]?.id; // Get the service ID from tableData
    // console.log(serviceId); // Log the service ID to check

    if (!serviceId) {
      // console.error("Service ID is undefined or invalid");
      return;
    }

    const newColors = [...iconColors]; // Assuming you're managing icon colors elsewhere
    setIconColors(newColors); // Update icon colors if needed

    try {
      const updatedService = await updateService({
        serviceId: serviceId,
        serviceData: { status: "treated" },
      });

      if (updatedService) {
        // console.log(updatedService.id, updatedService.status); // Log the updated service info
        toast.success("Service mis à jour avec succès");

        // Update table data with the new status
        const updatedData = tableData.map((row, i) =>
          i === index ? { ...row, status: "En attente" } : row
        );
        setTableData(updatedData); // Update the table with new data
      }
    } catch (error) {
      console.error("Failed to update service status:", error);
    }
  };
  const handleVert = async (index) => {
    const serviceId = tableData[index]?.id; // Get the service ID from tableData
    // console.log(serviceId); // Log the service ID to check

    if (!serviceId) {
      console.error("Service ID is undefined or invalid");
      return;
    }

    const newColors = [...iconColors]; // Assuming you're managing icon colors elsewhere
    setIconColors(newColors); // Update icon colors if needed

    try {
      const updatedService = await updateService({
        serviceId: serviceId,
        serviceData: { status: "pending" },
      });

      if (updatedService) {
        // console.log(updatedService.id, updatedService.status); // Log the updated service info
        toast.success("Service mis à jour avec succès");

        // Update table data with the new status
        const updatedData = tableData.map((row, i) =>
          i === index ? { ...row, status: "En attente" } : row
        );
        setTableData(updatedData); // Update the table with new data
      }
    } catch (error) {
      console.error("Failed to update service status:", error);
    }
  };
  const dropdownRef = useRef(null);
  const page = parseInt(searchParams.get("page")) || 1;
  const limit = parseInt(searchParams.get("limit")) || 5;
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;
  const slicedData = data.slice(startIndex, endIndex);
  const totalPages = Math.ceil(data.length / limit);
  const handleFilterChange = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
    setSearchParams(newSearchParams);
  }
  const handlePageChange = (newPage) => {
    handleFilterChange("page", newPage);
  };

  useEffect(() => {
    if (openDropdown !== null && dropdownRef.current) {
      const buttonRect = dropdownRef.current.getBoundingClientRect();
      const dropdownHeight = 4 * 40; // Assuming each status item is 40px tall
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;

      if (spaceBelow < dropdownHeight && spaceAbove >= dropdownHeight) {
        setDropdownPosition({ bottom: "75%", top: "auto" });
      } else {
        setDropdownPosition({ top: "75%", bottom: "auto" });
      }
    }
  }, [openDropdown]);

  const getStatusStyles = (status) => {
    let backgroundColor = "";
    switch (status) {
      case "À traiter en urgence":
        backgroundColor = "#EA281B";
        break;
      case "À traiter":
        backgroundColor = "#0078D7";
        break;
      case "En attente":
        backgroundColor = "#FFB800";
        break;
      default:
        backgroundColor = "#FFF098";
    }
    return {
      backgroundColor,
      textAlign: "center",
      fontWeight: 500,
      color: "#FFF",
      borderRadius: "20px",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      padding: "8px 14px",
      cursor: "pointer",
      position: "relative",
    };
  };

  const handleStatusChange = (index, newStatus) => {
    const updatedData = tableData.map((row, i) =>
      i === index ? { ...row, status: newStatus } : row
    );
    setTableData(updatedData);
    setOpenDropdown(null);
  };

  return (
    <div>
      <CadreTab>
        <table
          className="table"
          style={{
            fontSize: "14px",
            fontFamily: "Roboto",
            verticalAlign: "middle",
          }}>
          <thead className="table-light table p-2">
            <tr>
              <th className="p-2">Voyageurs</th>
              <th className="p-2">Services</th>
              <th className="p-2">Commandé le</th>
              <th className="p-2">Échéance</th>
              <th className="p-2">Status</th>
              <th className="p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td className="border-0"
                  colSpan="6"
                  style={{ textAlign: "center", padding: "40px 0" }}>
                  <div className="d-flex flex-column align-items-center">
                    <GrInbox style={{ color: "#DFDFDF", fontSize: "38px" }} />
                    <p
                      style={{
                        color: "#BFBFBF",
                        fontSize: "15.30px",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                        marginTop: "10px",
                      }}>
                      Aucun service commandé
                    </p>
                  </div>
                </td>
              </tr>
            ) : (
              slicedData.map((row, index) => (
                <tr key={index} style={{ marginTop: "px" }}>
                  <td className="py-0">
                    <div className="d-flex align-items-center">
                      <InfoPersonnel
                        classimg="classimg rounded-circle"
                        img={DrapeauSenegal}
                        value="Senegal"
                        style={{
                          color: "#667085",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                        }}
                      />
                      <div className="d-flex flex-column p-2">
                        <span style={{ fontWeight: 500, fontSize: "16px" }}>
                          {row.voyageur}
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#6C757D",
                            fontFamily: "Roboto",
                            fontWeight: 400,
                          }}>
                          Sénégal
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid #DEE2E6",
                      padding: "0px",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                    className="mt-2 p-2">
                    {row.accessoires}
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid #DEE2E6",
                      padding: "0px",
                    }}
                    className="mt-2 p-2">
                    {row.demandedAt}
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid #DEE2E6",
                      padding: "0px",
                    }}
                    className="mt-2 p-2">
                    {/* {row.period} */}
                    {"Pas de date"}
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid #DEE2E6",
                      padding: "0px",
                      position: "relative",
                    }}
                    className="mt-2">
                    <span
                      style={getStatusStyles(row.status)}
                      onClick={() => {
                        setOpenDropdown(openDropdown === index ? null : index);
                      }}>
                      {row.status}
                      <FaChevronDown style={{ marginLeft: "8px" }} />
                    </span>
                    {openDropdown === index && (
                      <div
                        style={{
                          position: "absolute",
                          left: "0",
                          backgroundColor: "#FFF",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          zIndex: 100,
                          width: "100%",
                          borderRadius: "4px",
                          ...dropdownPosition,
                        }}
                        ref={dropdownRef}>
                        {statuses.map((status, i) => (
                          <div
                            key={i}
                            onClick={() => handleStatusChange(index, status)}
                            className="status"
                            style={{
                              padding: "8px",
                              cursor: "pointer",
                              backgroundColor:
                                status === row.status ? "#F0F0F0" : "#FFF",
                            }}>
                            {status}
                          </div>
                        ))}
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="d-flex gap-2 fs-5">
                      <FaEye style={{ color: "gray", cursor: "pointer" }} />
                      {row.status !== "En attente" && (
                        <LuAlarmClock
                          style={{
                            color: "#FFB800",
                            cursor: "pointer",
                          }}
                          onClick={() => handleVert(index)}
                        />
                      )}
                      <IoCheckmarkCircleOutline
                        style={{
                          color: "#0078D7",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSetTreated(index)}
                      />
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </CadreTab>
      {
        data.length === 0 ? ("") :
      <Pagination totalPages={totalPages} currentPage={page} onPageChange={handlePageChange} />
      }
      <ToastContainer className="mt-5" style={{ zIndex: 100 }} />
    </div>
  );
};

export default BasicTable;
