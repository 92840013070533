import { ApiMananger } from "actions/rtkquery";
import { textePrepare } from "routes/api/endpoints";

export const textPrepareQueries = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    getTextesPrepare: builder.query({
      query: (userId) => ({
        url: textePrepare.addTextePrepare,
        method: "GET",
        params: { user: userId },
      }),
    }),
    getTextesPrepareById: builder.query({
      query: (id) => ({
        url: textePrepare.deleteTextePrepare.replace(":id", id),
        method: "GET",
      }),
    }),
  }),
});

export const { useGetTextesPrepareByIdQuery, useGetTextesPrepareQuery } = textPrepareQueries;
