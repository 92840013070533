import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

const LinkItem = styled(NavLink)`
  font-family: "Roboto";
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border-radius: 5px;
  width: 100%;
  font-weight: 500;
  text-decoration: none;
  color: ${({ color }) => color};
  &:hover {
    color: ${({ coloronhover }) => coloronhover};
  }
  &.active {
    color: ${({ coloronactive }) => coloronactive};
    background-color: ${({ activelinkbgcolor }) => activelinkbgcolor};
  }
`;

const DropdownMenu = styled.div`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  background: #8E715B;
  color: white;
  padding: 4px;
  border-radius: 4px;
  margin-top: 2px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const SubMenuItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: white;
  background: ${({ isActive }) => (isActive ? "#6C5747" : "transparent")};
  box-shadow: ${({ isActive }) => (isActive ? "black 0px 1px 6px 0px !important" : "")};
  text-decoration: none;
  border-radius: 4px;
  margin-bottom: 3px;
  &:hover {
    background: #6C5747;
  }
`;

const SidebarItem = ({
  linkItem,
  activeLinkColor,
  activeLinkBgColor,
  colorOnHover,
  linkColor,
  activeLink,
  setActiveLink,
}) => {
  const { path, label, submenu, icon: Icon } = linkItem;
  const isReduire = useSelector((state) => state?.persist?.sidebarWidth.isReduire);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);

  // Déterminer l'élément actif en fonction de location.pathname
  useEffect(() => {
    const findActiveItem = () => {
      if (submenu) {
        const activeSubmenuItem = submenu.find((subItem) => subItem.path !== "#" && location.pathname.includes(subItem.path));
        return activeSubmenuItem ? activeSubmenuItem.path : null;
      }
      return null;
    };

    setActiveItem(findActiveItem());
  }, [location.pathname, submenu]);

  const isParentActive = activeItem || location.pathname.includes(path);
  const isActive = activeLink === label || isParentActive;

  const handleClick = (e) => {
    e.preventDefault();
    setActiveLink(isActive ? null : label);
  };

  return (
    <>
      <LinkItem
        color={linkColor}
        coloronhover={colorOnHover}
        coloronactive={activeLinkColor}
        activelinkbgcolor={activeLinkBgColor}
        to={path}
        onClick={handleClick}
        className={isParentActive ? "active" : ""}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "12px",
            flexDirection: "column",
          }}
        >
          <Icon size={16} className="icon" />
          {!isReduire && (
            <p className="text-center" style={{ fontSize: 12, margin: 3 }}>
              {label}
            </p>
          )}
        </div>
      </LinkItem>
      {submenu && (
        <DropdownMenu isVisible={isActive}>
          {submenu.map((subItem, index) => (
            <SubMenuItem
              key={index}
              to={subItem.path}
              isActive={activeItem === subItem.path} // Vérification si l'élément est actif
            >
              <img src={subItem.icon} alt={subItem.label} style={{ width: 16 }} />
              <span style={{ fontSize: 12, textAlign: "center" }}>{subItem.label}</span>
            </SubMenuItem>
          ))}
        </DropdownMenu>
      )}
    </>
  );
};

export default SidebarItem;
