import React from "react";
import styled from "styled-components";

const CardBodyContainer = styled.div`
  box-shadow: 1px 1px 3px gray;
  margin: 13px;
  margin-bottom: 0;
  padding: 5px;
  border-radius: 9px;
`;

const Column = styled.div`
  padding-top: 10px;
  gap: 10px;
`;

const OkIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E7E8EA;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const PreTache = styled.p`
  color: #101828;
  font-weight: 500;
  font-size: 15px;
  font-family: Roboto;
  margin-left: 10px;
  margin-top: 6px;
`;

const CardBody = ({ children }) => {
  return <CardBodyContainer className="card-body row ">{children}</CardBodyContainer>;
};

const CardColumn = ({ children }) => {
  return <Column className="p-0" style={{ marginLeft: "0px " }}>{children}</Column>;
};

const OkIconContainer = ({ children }) => {
  return <OkIcon>{children}</OkIcon>;
};

const PreTacheText = ({ text }) => {
  return <PreTache>{text}</PreTache>;
};

export { CardBody, CardColumn, OkIconContainer, PreTacheText };
