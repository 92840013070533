import React from "react";
import HistoriqueCard from "./HistoriqueCard";

function HistoriqClient({ reservationsClient, formatDate }) {
  return (
    <div className="row p-0 m-0">
      {reservationsClient.map((reservations, index)=>((
        <HistoriqueCard key={index} number={reservations.id} montant={reservations.montant_total} statut={reservations.statut_reservation}
          debut={formatDate(reservations.date_debut_sejour)} fin={formatDate(reservations.date_fin_sejour)} />
      )))}
    </div>
  )
}

export default HistoriqClient