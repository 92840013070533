import React from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "./NouveauVoyageurCss";

export default function InputCalendrier({ label, name, minDate }) {
  const { register, setValue, getValues, formState: { errors } } = useFormContext();

  const handleChange = (e) => {
    const selectedDate = e.target.value;
    setValue(name, selectedDate); // Met à jour l'état du formulaire avec la date sélectionnée
  };

  return (
    <>
      <Label className="mb-1" htmlFor={name}>
        {label}
        <span style={{ color: "red" }}>*</span>
      </Label>
      <input
        id={name}
        type="date"
        defaultValue={getValues(name) || ""} // Utilise defaultValue au lieu de value pour autoriser la saisie manuelle
        onChange={handleChange} // Gère le changement de date
        min={minDate || ""} // Définit la date minimale
        className={errors[name] ? "p-invalid" : ""}
        style={{ height: "40px" }}
        {...register(name)} // Utilise react-hook-form pour enregistrer l'input
      />
      {/* {errors[name] && ( // Display error message if it exists
        <p style={{ color: "red" }}>{errors[name].message}</p>
      )} */}
    </>
  );
}
